import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

// import authReducer from './slices/authSlice'
// import UserReducer from '../screens/authScreens/slice'
import UserReducer from '../screens/dashboard/slice'
import notificationReducer from './slices/notificationSlice'
import loaderReducer from './slices/loaderSlice'
import successModelReducer from './slices/successModelSlice'
import profileReducer from '../screens/profileUpdate/slice'
const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    //auth: authReducer,
    user: UserReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: {
        persistedReducers: persistedReducer,//localstorage saing for percicist
        showNotification: notificationReducer,
        loader: loaderReducer,
        UserReducer: UserReducer,
        successModelReducer: successModelReducer,
        profileReducer: profileReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

// const persistor = persistStore(store)

export { store }