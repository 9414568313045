import { createSlice } from '@reduxjs/toolkit'

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    show: false,
  },
  reducers: {
    SHOW_LOADER(state) {
      state.show = true;
    },
    HIDE_LOADER(state) {
      state.show = false;
    },
  },
});

export const { SHOW_LOADER, HIDE_LOADER } = loaderSlice.actions
export const selectLoader = (state: { loader: any; }) => state.loader
export default loaderSlice.reducer