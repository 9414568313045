import React, { ReactNode } from 'react';
import { Grid, Box, Link, Button } from '@mui/material';
import { FormHeadingTypography, FormFooterTypography, FormSubHeadingTypography } from '../../../../components/FormComponent/CustomElements';

interface FormWrapperProps {
    brandLogo: string;
    heading: string;
    subHeading?: string;
    children: ReactNode;
    validate: () => void;
    listOfClient?: any[];
}
const contactEmail = "client.support@siptele.com"
const contactPhone = "877-757-8353"

const FormLayout: React.FC<FormWrapperProps> = ({ brandLogo, heading, subHeading, children, validate, listOfClient }) => {
    return (
        <Grid item xs={12} md={6} >
            <Box
                sx={{
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', }}>
                    <Box>
                        <img
                            src={require(`../../../../assets/images/${brandLogo}`)}
                            alt="Brand"
                            className='brand-image'
                        />
                    </Box>
                    <FormHeadingTypography >
                        {heading}
                    </FormHeadingTypography>
                    {subHeading && <FormSubHeadingTypography >  {subHeading}  </FormSubHeadingTypography>}
                    {children}
                    <Button variant="contained" color="primary" size='large' fullWidth onClick={validate}>
                        {
                            listOfClient && listOfClient?.length > 1 ? "VALIDATE CLIENT" : "SEARCH"
                        }
                    </Button>
                    <FormFooterTypography align="center">
                        For any issues Email :{" "}
                        <Link href={`mailto:${contactEmail}`} color="primary.dark" underline="none">{contactEmail}</Link>
                        <br /> or<br />
                        Phone :{" "} <Link href={`tel:${contactPhone}`} color="primary.dark" underline="none">{contactPhone}</Link>.
                    </FormFooterTypography>
                </Box>
            </Box>
        </Grid>
    );
}
export default FormLayout;