import React from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import FormLayout from '../FormLayout';
interface FormData {
    brandLogo: string;
    zipCode: string;
    therapistGender: string;
    handleChange: (e: any) => void;
    validate: () => void;
    errorMsg: {
        [key: string]: string | undefined;
    };
}

const Form: React.FC<FormData> = ({ brandLogo, zipCode, therapistGender, handleChange, validate, errorMsg }) => {
    const handleZip = (e: any) => {
        e.target.value = e.target.value.replace(/\D/g, '');
        handleChange(e);
    }
    return (
        <FormLayout
            brandLogo={brandLogo}
            heading={""}
            validate={validate}
        >
            <TextField
                label="Your Zipcode"
                value={zipCode}
                name='zipCode'
                variant="outlined"
                size="medium"
                margin="normal"
                fullWidth
                required
                className='custom-input'
                onChange={handleZip}
                inputProps={{ maxLength: 5 }}
                error={!!errorMsg.zipCode}
            />
            <FormControl fullWidth required error={!!errorMsg.therapistGender}>
                <InputLabel id="gender-select-label">Therapist Gender</InputLabel>
                <Select
                    labelId="gender-select-label"
                    name='therapistGender'
                    id="gender-select"
                    value={therapistGender || ''}
                    label="Therapist Gender"
                    required
                    fullWidth
                    className='custom-input'
                    onChange={handleChange}                                        >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                </Select>
            </FormControl>
        </FormLayout>
    );
};

export default Form;