import * as Yup from 'yup';

export const mobileVerificationSchema = Yup.object().shape({
    dob: Yup.string().required(),
    listOfClient: Yup.array(),
    selectedClient: Yup.object().test(
        'is-required-when-multiple-clients',
        'Please select a client',
        function (value) {
            const { listOfClient } = this.parent; // Access listOfClient field from the parent context
            if (listOfClient && listOfClient.length > 1) {
                // If listOfClient has more than 1 item, selectedClient is required
                return value !== null && value !== undefined && value !== '' && Object.keys(value).length > 0;
            }
            return true; // Otherwise, it's not required
        }
    ),
});

export const zipInputSchema = Yup.object().shape({
    zipCode: Yup.string().min(5).required(),
    therapistGender: Yup.string().required(),
});
