import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLinkData } from "./utils";
import { Container } from "@mui/material";
import { MobileStep, ZipCodeStep, LocationStep, SlotStep } from "./components";
import { useNavigate, useLocation } from "react-router-dom";
import { User } from "./slice";
import SuccessModal from "../../components/FormComponent/SuccessModel";
import InvalidPage from "../../components/FormComponent/InvalidPage";
import ProfileUpdate from "../profileUpdate";
import { SHOW_SUCCESS_MODEL } from "../../redux/slices/successModelSlice";
import MedicalHistory from "../medicalHistory";

const Dashboard: React.FC = () => {
  const modelData = useSelector((state: any) => state.successModelReducer); // visit create model data, when u click on slot
  const { DemographicUpdatedon } = useSelector(
    (state: any) => state.UserReducer.selectedClient
  );
  const { decryptApiData } = useSelector((state: any) => state.UserReducer);
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepForUpdate, setActiveStepForUpdate] = useState("0");
  const [isProfileUpdated, setIsProfileUpdated] = useState(false); // activate profile update page
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientLink = queryParams.get("OT");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    dispatch(User({ [event.target.name]: event.target.value }));
  };
  const [shouldShowProfileUpdate, setshouldShowProfileUpdate] = useState(false);
  const [shouldShowMedicalUpdate, setshouldShowMedicalUpdate] = useState(false);
  // const shouldShowProfileUpdate =
  // (decryptApiData?.T === "2") || (activeStepForUpdate === "2");
  const handleNext = () => {
    if (decryptApiData && decryptApiData?.T ==="1" && activeStep === 0) {
      setActiveStepForUpdate(decryptApiData?.T);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else  if (decryptApiData && decryptApiData?.T ==="2" && activeStep === 0) {
      setActiveStepForUpdate(decryptApiData?.T);
      setshouldShowProfileUpdate(true);
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else  if (decryptApiData && decryptApiData?.T ==="3" && activeStep === 0) {
      setActiveStepForUpdate(decryptApiData?.T);
      setshouldShowMedicalUpdate(true);
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
     else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = () => {
    if (DemographicUpdatedon === null) {
      setIsProfileUpdated(true); // Show profile update form
    } else {
      dispatch(SHOW_SUCCESS_MODEL({ visiblity: true })); // Show success model
    }
  };

  const validateLink = async () => {
    await getLinkData({
      clientLink: clientLink ?? "",
      dispatch,
      setError,
      navigate,
    });
  };

  useEffect(() => {
    if (activeStep === 0) {
      validateLink();
    }
  }, [activeStep]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? Any unsaved changes will be lost.";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleCloseModel = () => {
    dispatch(SHOW_SUCCESS_MODEL({ visiblity: false }));
  };

  // Check if ProfileUpdate should be shown
 


  return (
    <>
      {clientLink ? (
        !isProfileUpdated ? (
          <Container maxWidth="xl" sx={{ flex: 1 }}>
           {activeStep === 0 && !shouldShowProfileUpdate && !shouldShowMedicalUpdate && (
              <MobileStep handleChange={handleChange} handleNext={handleNext} />
            )}
            {/* Conditionally show MedicalHistory */}
            {shouldShowMedicalUpdate && <MedicalHistory />}
            {shouldShowProfileUpdate ? (
              <>
               <ProfileUpdate />              
              </>
            ) : (
              <>
                {activeStep === 1 &&
                  (activeStepForUpdate === "0" ||
                    activeStepForUpdate === "1") && (
                    <ZipCodeStep
                      handleChange={handleChange}
                      handleNext={handleNext}
                    />
                  )}
                {activeStep === 2 &&
                  (activeStepForUpdate === "0" ||
                    activeStepForUpdate === "1") && (
                    <LocationStep
                      handleChange={handleChange}
                      handleNext={handleNext}
                    />
                  )}
                {activeStep === 3 &&
                  (activeStepForUpdate === "0" ||
                    activeStepForUpdate === "1") && (
                    <SlotStep
                      handleSubmit={handleSubmit}
                      handlePrev={handlePrev}
                    />
                  )}
              </>
            )}
            {modelData.visiblity && (
              <SuccessModal
                open={modelData.visiblity}
                onClose={() => handleCloseModel()}
                {...modelData}
              />
            )}
          </Container>
        ) : (
          <ProfileUpdate />
        )
      ) : (
        <Container maxWidth="xl" sx={{ flex: 1 }}>
          <InvalidPage />
        </Container>
      )}
    </>
  );
};

export default Dashboard;
