import { API } from '../../../utils/apiList'
import axios from '../../../utils/axiosInstance'
import { CreateAppointArgs } from '../type'
const validateLinkApi = (link: string) => {
    const response = axios.get(API.DecryptParams, { params: { data: link } })
    return response
}

const getClientDataByIdApi = (clientID: string) => {
    const response = axios.get(API.GetClientById, { params: { clientID } })
    return response
}

const getClientsByPhoneDOBApi = (Mobilenumber: string, DOB: string, companyid: string) => {
    const response = axios.get(API.GetClientByPhoneDob, { params: { companyid, DOB, Mobilenumber } })
    return response
}

const getLocationForTreatmentApi = (zipcode: string, companyid: string, genderid: string, pos: string) => {
    const response = axios.get(API.getLocationForTreatment, { params: { zipcode, companyid, genderid, pos } })
    return response
}

const getAvailableSlotsApi = (clientid: string, treatmetnLocationid: string, genderid: string, pos: string) => {
    const response = axios.get(API.fetchAvailableSlots, { params: { clientid, treatmetnLocationid, genderid, pos } })
    return response
}
const createAppointmentApi = async (data: CreateAppointArgs) => {
    try {
        const response = await axios.post(API.createEvalAppointmentBySchool, { ...data });
        if (response?.status === 200 && response.data?.Result) {
            const visit = await getVisitDataAfterVisitCreateApi(data.TreatmentLocationId);
            visit.data.Result.VisitId = response.data.Result; 
            //(visit as any).VisitId = response.data.Result; 
           // console.log(visit)
           //alert(JSON.stringify(visit))
            return visit;
        }
        return false;  
    } catch (error) {
        console.error("Error creating appointment:", error);
        return false;  // Return false if there is any error
    }
};

// const createAppointmentApi = async (data: CreateAppointArgs) => {
//     const response = await axios.post(API.createEvalAppointmentBySchool, { ...data })
//     if (response?.status === 200) {
//         const visit = await getVisitDataAfterVisitCreateApi(data.TreatmentLocationId)
//         (visit as any).VisitId = response.data.Result;
//         return visit
//     }
//     return false
// }

const getFutureVisitApi = (clientid: string, SMSDate: string) => {
    const response = axios.get(API.getFutureVisit, { params: { clientid, SMSDate } })
    return response
}

const getValidClientForEval = (clientid: string, treatmentTypeId: string) => {
    const response = axios.get(API.ValidClientForEval, { params: { clientid } })
    return response
}

const getVisitDataAfterVisitCreateApi = (treatmentlocationId: any) => {
    const response =  axios.get(API.getTreatmentLocationById, { params: { treatmentlocationId } })
    //console.log(response)
    //alert(JSON.stringify(response))
    return response
}

export {
    validateLinkApi,
    getClientDataByIdApi,
    getClientsByPhoneDOBApi,
    getLocationForTreatmentApi,
    getAvailableSlotsApi,
    createAppointmentApi,
    getFutureVisitApi,
    getValidClientForEval,
    getVisitDataAfterVisitCreateApi,
}