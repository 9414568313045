import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_LOADER, HIDE_LOADER } from "../../../../redux/slices/loaderSlice";
import { MedicalHistory } from "../../../dashboard/slice";
import { RequireAssistanceOptions } from "../step1Form/options";
import { saveMedicalHistory, validateUserInfoForm } from "../../utils";
import { Errors } from "../../types";
import { RequireAssistanceSchema } from "../../schema";
import { SHOW_NOTIFICATION } from "../../../../redux/slices/notificationSlice";

interface Step3FormProps {
  handleNext: () => void;
  handlePrev: () => void;
  showConsentStep: boolean;
}

const Step3Form: React.FC<Step3FormProps> = ({
  handlePrev,
  handleNext,
  showConsentStep,
}) => {
  const dispatch = useDispatch();
  const {
    decryptApiData,
    selectedClient,
    medicalHistory,
    treatmentLocationDetail,
  } = useSelector((state: any) => state.UserReducer);
  const { Clientid: clientID } = selectedClient || {};
  const [errors, setErrors] = useState<Errors>({});
  const { RequireAssistance: requireAssistance } = medicalHistory || {};
  const { TherapistType, DiagnosisIssue } = medicalHistory || {};
  const { VisitId } = treatmentLocationDetail || {};
  // Initialize formData with requireAssistance
  const [formData, setFormData] = useState({
    requireAssistance: requireAssistance || "",
  });

  // Convert comma-separated string to an array of selected items
  const [selectedItems, setSelectedItems] = useState<string[]>(
    requireAssistance ? requireAssistance.split(";") : []
  );

  useEffect(() => {
    setSelectedItems(requireAssistance ? requireAssistance.split(";") : []);
  }, [requireAssistance]);

  const handleChange = (event: {
    target: { name: string; checked: boolean };
  }) => {
    const { name, checked } = event.target;

    setSelectedItems((prevItems) => {
      if (name === "None" && checked) {
        // If "None" is selected, clear all other options
        setFormData((prev) => ({
          ...prev,
          requireAssistance: "None", // Set only "None" in the form data
        }));
        return ["None"]; // Set only "None" in selected items
      }

      if (checked) {
        // If an item is checked and "None" was previously selected, remove "None"
        if (prevItems.includes("None")) {
          setFormData((prev) => ({
            ...prev,
            requireAssistance: name, // Set only the new checked item
          }));
          return [name];
        } else {
          setFormData((prev) => ({
            ...prev,
            requireAssistance: [...prevItems, name].join(";"), // Add the new item
          }));
          return [...prevItems, name]; // Add item to the array
        }
      } else {
        // If an item is unchecked
        const updatedItems = prevItems.filter((item) => item !== name);
        setFormData((prev) => ({
          ...prev,
          requireAssistance: updatedItems.join(";"), // Update form data with remaining items
        }));
        return updatedItems; // Remove item from the array
      }
    });
  };

  // const handleChange = (event: {
  //   target: { name: string; checked: boolean };
  // }) => {
  //   const { name, checked } = event.target;

  //   setSelectedItems((prevItems) => {
  //     if (checked) {
  //       return [...prevItems, name]; // Add item to the array
  //     } else {
  //       return prevItems.filter((item) => item !== name); // Remove item from the array
  //     }
  //   });

  //   // Update formData with the updated selected items as a comma-separated string
  //   setFormData((prev) => ({
  //     ...prev,
  //     requireAssistance: checked
  //       ? [...selectedItems, name].join(";")
  //       : selectedItems.filter((item) => item !== name).join(";"),
  //   }));
  // };

  const handleSubmit = async () => {
    try {
      //alert(JSON.stringify(decryptApiData?.VID))
      dispatch(SHOW_LOADER());
      await validateUserInfoForm(RequireAssistanceSchema, formData, setErrors);
      const payload = {
        ...formData,
        // therapistType: TherapistType,
        //diagnosisIssue: DiagnosisIssue,
        VisitId:VisitId,//VisitId || VisitId==="0"?decryptApiData?.VID: VisitId,
        OnbordingEventType: 3,
        clientBioUpdateAction: 3,
        clientid: parseInt(
          decryptApiData.S === "8" ? decryptApiData?.ID : clientID
        ),
        updateBy: parseInt(decryptApiData?.ID),
      };

      dispatch(
        MedicalHistory({
          RequireAssistance: formData.requireAssistance,
        })
      );
      //alert(JSON.stringify(decryptApiData));
      if (decryptApiData?.T === "3") {
        dispatch(
          SHOW_NOTIFICATION({
            message: "Medical history updated successfully.", // Your success message
            type: "success", // Set the type to 'success'
            head: "Success!", // Title of the notification
          })
        );
      }
      //alert(JSON.stringify(payload));
      await saveMedicalHistory(payload);
      handleNext();
    } catch (e) {
      console.error(e, "--- Error in form submission");
    } finally {
      dispatch(HIDE_LOADER());
    }
  };

  const options = RequireAssistanceOptions;

  return (
    <Grid
      container
      spacing={0}
      style={{ marginLeft: "200px", padding: "24px" }}
      justifyContent="center"
      md={10}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "17px" }}
        >
          Please select if you require assistance with any of the following
          Activities of Daily Living (ADL)?
        </Typography>
      </Grid>
      {options.map((item) => (
        <Grid item xs={12} sm={4} key={item.id}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedItems.includes(item.id)}
                    onChange={handleChange}
                    name={item.id}
                    color="primary"
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      ))}
      {errors.requireAssistance && (
        <Grid item xs={12} textAlign={"center"}>
          <Typography color="error" variant="h6">
            Please select at least one option.
          </Typography>
        </Grid>
      )}
      <Grid
        item
        md={12}
        sx={{ display: "flex", justifyContent: "center", gap: 3 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrev}
          sx={{ width: "100px" }}
        >
          PREVIOUS
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: "100px" }}
        >
          SAVE
        </Button>
      </Grid>
    </Grid>
  );
};

export default Step3Form;
