import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, FormControlLabel, Checkbox, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../slice/index';
import { validateUserInfoForm, saveUserCommunication } from '../../utils';
import { Errors } from '../../types';
import { HIDE_LOADER, SHOW_LOADER } from '../../../../redux/slices/loaderSlice';
import { userContactSchema } from '../../schema';
import { FormHeadingTypography } from '../../../../components/FormComponent/CustomElements';
import { User } from '../../../dashboard/slice';
interface UserInfoFormProps {
    handleNext: () => void;
    handlePrev: () => void;
}


const UserCommunicationForm: React.FC<UserInfoFormProps> = ({ handlePrev, handleNext }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<Errors>({});

    // const { mobile, primaryEmail, textNotification, emailNotification, decryptApiData, clientID } = useSelector((state: any) => state.profileReducer);
    const { decryptApiData, selectedClient } = useSelector((state: any) => state.UserReducer);
    const { Clientid: clientID } = useSelector((state: any) => state.UserReducer?.selectedClient);

    const { Mobile: mobile, Email: primaryEmail } = useSelector((state: any) => state.UserReducer.selectedClient.ContactInfoDTO);
    const { NoSMS: textNotification, NoEmail: emailNotification } = useSelector((state: any) => state.UserReducer.selectedClient);

    const [formData, setFormData] = useState({ mobile, primaryEmail, textNotification, emailNotification });
    useEffect(() => {
        setFormData({ mobile, primaryEmail: primaryEmail ? primaryEmail : "", textNotification: Boolean(emailNotification), emailNotification: Boolean(emailNotification) });
    }, [mobile, primaryEmail, textNotification, emailNotification]);

    const updateVal = (e: React.ChangeEvent<HTMLInputElement>) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const handleSubmit = async () => {
        try {
            dispatch(SHOW_LOADER());
            await validateUserInfoForm(userContactSchema, formData, setErrors);
            const payload = {
                ...formData,
                clientBioUpdateAction: 2,
                clientid: parseInt(decryptApiData.S === "8" ? decryptApiData?.ID : clientID),
                updateBy: parseInt(decryptApiData?.ID),
            }
            await saveUserCommunication(payload);
            // dispatch(Profile({ ...formData }))
            dispatch(User({
                selectedClient: {
                    ...selectedClient,
                    NoSMS: formData.textNotification,
                    NoEmail: formData.emailNotification,
                    ContactInfoDTO: {
                        ...selectedClient.ContactInfoDTO,
                        Mobile: formData.mobile,
                        Email: formData.primaryEmail
                    }
                }
            }))
            handleNext();
        }
        catch (e) {
            console.log(e, "---eee")
        }
        finally {
            dispatch(HIDE_LOADER());
        }
    }

    const handleMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/\D/g, '');
        updateVal(e);
    }

    return (
        <Grid container spacing={3} style={{ padding: "24px" }} md={10} >
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Phone Number"
                    variant="outlined"
                    name="mobile"
                    fullWidth
                    value={formData.mobile}
                    required
                    error={!!errors.mobile}
                    onChange={handleMobile}
                    inputProps={{ maxLength: 10 }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Primary Email"
                    variant="outlined"
                    name="primaryEmail"
                    fullWidth
                    value={formData.primaryEmail}
                    required
                    error={!!errors.primaryEmail}
                    onChange={updateVal}
                />
            </Grid>
            <Grid item sm={12}>
                <FormHeadingTypography>Appointments</FormHeadingTypography>
                <Box sx={{ ml: 2, display: "flex", flexDirection: "column", width: "fit-content" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.textNotification}
                                onChange={() => setFormData(prev => ({ ...prev, textNotification: !formData.textNotification }))}
                                inputProps={{ 'aria-label': 'txtMsg' }}
                            />}
                        label="Receive patient notifications/reminder via text"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.emailNotification}
                                onChange={() => setFormData(prev => ({ ...prev, emailNotification: !formData.emailNotification }))}
                                inputProps={{ 'aria-label': 'emailMsg' }}
                            />}
                        label="Receive patient notifications/reminder via email"
                    />
                </Box>
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: 'center', gap: 3 }}>
                <Button variant="contained" color="primary" onClick={handlePrev} sx={{ width: "100px" }}>
                    PREVIOUS
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ width: "100px" }}>
                    NEXT
                </Button>
            </Grid >
        </Grid>
    );
}

export default UserCommunicationForm;
