import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Typography, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../slice/index';
import { validateUserInfoForm, loadConsent } from '../../utils';
import { Errors } from '../../types';
import { HIDE_LOADER, SHOW_LOADER } from '../../../../redux/slices/loaderSlice';
import { userConsentSchema } from '../../schema';
import { FormHeadingTypography, FormHeadingTypography16 } from '../../../../components/FormComponent/CustomElements';
import { useTheme } from '@mui/material/styles';
import ConsentPopup from './popup';
interface UserConsentFormProps {
    handlePrev: () => void;
    handleSubmit: () => void;
}

const UserConsentForm: React.FC<UserConsentFormProps> = ({ handlePrev, handleSubmit }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<Errors>({});
    const theme = useTheme();
    const [consentData, setConsentData] = useState<any>({});
    const [openConsent, setOpenConsent] = useState(false);
    // const { dob, firstName, lastName, acknowledge, consentBy, brandName } = useSelector((state: any) => state.profileReducer);
    const { FirstName: firstName, LastName: lastName, DOB: dob, ConsentBy: consentBy, BrandName: brandName, BrandCode: brandCode } = useSelector((state: any) => state.UserReducer.selectedClient);

    const [formData, setFormData] = useState({ dob, firstName, lastName, acknowledge: false, consentBy }); //loading into local state to avoid re-rendering andmake it fast

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            dob, firstName, lastName, consentBy
        }));
    }, [dob, firstName, lastName, consentBy]);

    useEffect(() => { loadConsent(setConsentData, brandName, brandCode) }, []);

    //const updateVal = (e: React.ChangeEvent<HTMLInputElement>) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    // Update form values and dispatch changes to Redux store
    const updateVal = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
// alert(name)
// alert(value)
        // Update local state
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        // Dispatch updated form data to Redux store
        dispatch(Profile({
            ...formData,
            [name]: value
        }));
    };


    const submitFun = async () => {
        try {
            //alert("submitFun")
            dispatch(SHOW_LOADER());
            await validateUserInfoForm(userConsentSchema, formData, setErrors);
            dispatch(Profile({ ...formData }))
            handleSubmit();
        }
        catch (e) {
            console.log(e, "---eee")
        }
        finally {
            dispatch(HIDE_LOADER());
        }
    }

    const acknowledgeFun = (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrors(prev => ({ ...prev, acknowledge: false }));
        setFormData(prev => ({ ...prev, acknowledge: e.target.checked }));
    }

    return (
        <Grid container spacing={3} style={{ padding: "24px" }} md={12} >
            <Grid item sm={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                <FormHeadingTypography> {brandName} New Patient Agreement</FormHeadingTypography>
                <FormHeadingTypography16> Date of birth : {dob}</FormHeadingTypography16>
                <FormHeadingTypography16> Client name : {firstName}, {lastName}</FormHeadingTypography16>
            </Grid>
            <Grid item sm={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.acknowledge}
                            onChange={acknowledgeFun}
                            inputProps={{ 'aria-label': 'txtMsg' }}
                            sx={{
                                "& .MuiSvgIcon-root": {
                                    color: errors.acknowledge ? theme.palette.error.main : 'inherit',
                                }
                            }}
                        />}
                    label={<FormHeadingTypography16>PATIENT / RESPONSIBLE PARTY ACKNOWLEDGMENT</FormHeadingTypography16>}
                />
                <Button variant="outlined" color="primary" onClick={() => setOpenConsent(!openConsent)}>
                    VIEW CONSENT
                </Button>
            </Grid>
            <Grid item sm={4}>
                <TextField
                    label="Consent by"
                    variant="outlined"
                    name="consentBy"
                    fullWidth
                    value={formData.consentBy}
                    required
                    error={!!errors.consentBy}
                    onChange={updateVal}
                />
            </Grid>
            <Grid item xs={4}>
            </Grid>

            <Grid item md={12} sx={{ display: "flex", justifyContent: 'center', gap: 3 }}>
                <Button variant="contained" color="primary" onClick={handlePrev} sx={{ width: "100px" }}>
                    PREVIOUS
                </Button>
                <Button variant="contained" color="primary" onClick={submitFun} sx={{ width: "100px" }}>
                    SAVE{/* SIGN IN */}
                </Button>
            </Grid>
            {
                openConsent &&
                <ConsentPopup open={openConsent} onClose={() => setOpenConsent(false)} content={consentData} />
            }
        </Grid >
    );
}

export default UserConsentForm;
