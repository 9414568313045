import React from 'react';
import { ZipInputProps } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';
import LeftImageComponent from '../../../../components/FormComponent/LeftImageComponent';
import { getLocationForTreatment } from '../../utils'
import FormComponent from './form';
import { validateUserInfoForm } from '../../utils'
import { zipInputSchema } from '../../schema'
import moment from 'moment';
import { SHOW_SUCCESS_MODEL } from '../../../../redux/slices/successModelSlice';
import dateFormat from '../../../../utils/dateFormat';
import { getFutureVisitContent } from '../../utils';

const ZipInput: React.FC<ZipInputProps> = ({ handleChange, handleNext }) => {
    const { zipCode, therapistGender, brandLogo, companyID, errorMsg } = useSelector((state: any) => state.UserReducer)
    const isMobile = useMediaQuery('(max-width: 600px)');
    const dispatch = useDispatch()

    const validate = async () => {
        const formData = { zipCode, therapistGender };
        try {
            await validateUserInfoForm(zipInputSchema, formData, dispatch);
            await getLocationForTreatment({ zipCode, dispatch, companyId: companyID, therapistGender, handleNext })
           
          
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <Grid container
            sx={{ display: 'flex', alignItems: 'center', height: '100%', }} >
            {isMobile ? (
                <FormComponent
                    brandLogo={brandLogo}
                    zipCode={zipCode}
                    therapistGender={therapistGender}
                    handleChange={handleChange}
                    validate={validate}
                    errorMsg={errorMsg}
                />
            )
                :
                (
                    <>
                        <LeftImageComponent />
                        <FormComponent
                            brandLogo={brandLogo}
                            zipCode={zipCode}
                            therapistGender={therapistGender}
                            handleChange={handleChange}
                            validate={validate}
                            errorMsg={errorMsg}
                        />
                    </>
                )
            }
        </Grid>
    );
}

export default ZipInput;
