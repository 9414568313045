import { API } from '../../../utils/apiList'
import axios from '../../../utils/axiosInstance'

const getStateListApi = () => {
    const response = axios.get(API.getStates)
    return response
}
const updateBioApi = (data: any) => {
    const response = axios.post(API.updateBio, data)
    return response
}
const loadConsentApi = () => {
    const response = axios.get(API.loadConsent, { baseURL: process.env.REACT_APP_API_SCHOOL_URL })
    return response
}

const saveConsentApi = (data: any) => {
    const response = axios.post(API.saveConsentForm, data)
    return response
}

export {
    getStateListApi,
    updateBioApi,
    loadConsentApi,
    saveConsentApi
}