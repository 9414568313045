import React from 'react';
import { Box, Button, TextField, Grid, FormControl, InputLabel, MenuItem, Select, Typography, Card, CardContent } from '@mui/material';
import { LocationSelectProps } from './types';
import { useSelector, useDispatch } from 'react-redux';
import { getAvailableSlots } from '../../utils'
import { FormHeadingTypography, HeadBar } from '../../../../components/FormComponent/CustomElements'
import AddressCard from '../../../../components/FormComponent/AddressCard'
import { zipInputSchema } from '../../schema'
import { validateUserInfoForm } from '../../utils'
import { getLocationForTreatment } from '../../utils'

const SelectLocation: React.FC<LocationSelectProps> = ({ handleChange, handleNext }) => {
    // const { zipCode, brandLogo, therapistGender, clientID, companyID, errorMsg } = useSelector((state: any) => state.UserReducer)
    const { BrandLogo: brandLogo, Clientid: clientID, CompanyId: companyID, } = useSelector((state: any) => state.UserReducer?.selectedClient)
    const { Zip: zipCode } = useSelector((state: any) => state.UserReducer?.selectedClient?.ContactInfoDTO)
    const { alternateLocations, recommendedLocations, errorMsg, therapistGender } = useSelector((state: any) => state.UserReducer)
    const dispatch = useDispatch()
    const genderSelect = (e: any) => {
        const event = {
            target: {
                name: 'therapistGender',
                value: e.target.value
            }
        } as React.ChangeEvent<HTMLInputElement>;
        handleChange(event);
    }

    const submit = (treatmentLoc: string | number, locName: string) => {
        getAvailableSlots({ clientID, companyId: companyID, locName, treatmetnLocationId: treatmentLoc.toString(), therapistGender, dispatch, handleNext })
    }

    const handleZipCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/\D/g, '');
        handleChange(e);
    }
    const search = async () => {
        const formData = { zipCode, therapistGender };
        try {
            await validateUserInfoForm(zipInputSchema, formData, dispatch);
            getLocationForTreatment({ zipCode, dispatch, companyId: companyID, therapistGender })
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <Grid container sx={{ flexDirection: 'column', padding: 2 }}>
            <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "center", mb: 3 }}>
                <img
                    src={require(`../../../../assets/images/${brandLogo}`)}
                    alt="Brand"
                    className='brand-image'
                />
            </Box>
            <Box sx={{ mb: 3 }}>
                <FormHeadingTypography sx={{ mb: 3 }}>
                    Find Location
                </FormHeadingTypography>
                <Grid container spacing={2} >
                    <Grid item sm={2}>
                        <TextField
                            label="Your Zipcode"
                            value={zipCode}
                            name="zipCode"
                            onChange={handleZipCode}
                            variant="outlined"
                            size="medium"
                            margin="normal"
                            fullWidth
                            required
                            className='custom-input'
                            sx={{ m: 0 }}
                            inputProps={{ maxLength: 5 }}
                            error={!!errorMsg.zipCode}
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <FormControl fullWidth required error={!!errorMsg.therapistGender}>
                            <InputLabel id="gender-select-label">Therapist Gender</InputLabel>
                            <Select
                                labelId="gender-select-label"
                                id="gender-select"
                                value={therapistGender || ''}
                                label="Therapist Gender"
                                required
                                name='therapistGender'
                                className='custom-input'
                                onChange={genderSelect}                                        >
                                <MenuItem value="Male">Male</MenuItem>
                                <MenuItem value="Female">Female</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={1}>
                        <Button variant="contained" color="primary" fullWidth sx={{ height: '100%' }} onClick={search}>
                            SEARCH
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            {/* Recommended Location Section */}
            {
                recommendedLocations.length > 0 &&
                <Box sx={{ display: 'contents' }}>
                    <HeadBar>
                        <FormHeadingTypography sx={{ color: '#fff !important', fontWeight: '500 !important' }}>
                            Recommended Location
                        </FormHeadingTypography>
                    </HeadBar>
                    <Grid container spacing={2}>
                        {recommendedLocations.map((location: any) => (
                            <AddressCard location={location} submit={submit} />
                        ))}
                    </Grid>
                </Box>
            }

            {/* Alternate Location Section */}
            {
                alternateLocations.length > 0 &&
                <Box sx={{ display: 'contents' }}>
                    <HeadBar>
                        <FormHeadingTypography sx={{ color: '#fff !important', fontWeight: '500 !important' }}>
                            Alternate Location
                        </FormHeadingTypography>
                    </HeadBar>
                    <Grid container spacing={2}>
                        {alternateLocations.map((location: any) => (
                            <AddressCard location={location} submit={submit} />
                        ))}
                    </Grid>
                </Box>
            }
            {
                recommendedLocations.length === 0 && alternateLocations.length === 0 &&
                <Box sx={{ display: 'contents' }}>
                    <Card sx={{ width: '100%', mt: 2 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                                No Clinic Location Found
                            </Typography>
                            <Typography variant="body2">
                                No Clinic location found for the given zip code
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
            }
        </Grid>
    );
}

export default SelectLocation;
