import React from 'react';
import { Grid, Card, CardContent, Box, Button, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormHeadingTypography, FormSubHeadingTypography } from '../../../components/FormComponent/CustomElements';

interface AddressCardProps {
    location: {
        id: number;
        TreatmentLocationId: number;
        LocationName: string;
        FirstAvailableSlot: string;
        AddressLine1: string;
        AddressLine2: string;
        Mobile: string;
        LinkUrl: string;
    };
    submit: (TreatmentLocationId: number, LocationName: string) => void;
}

const AddressCard: React.FC<AddressCardProps> = ({ location, submit }) => {
    const theme = useTheme();
    return (
        <Grid item md={4} key={location.id}>
            <Card key={location.TreatmentLocationId} sx={{ mb: 2, boxShadow: 'none' }}>
                <CardContent>
                    <FormHeadingTypography sx={{ color: `${theme.palette.secondary.main} !important`, mb: 1 }}>{location.LocationName}</FormHeadingTypography>
                    <Box sx={{ mb: 1 }}>
                        <FormSubHeadingTypography >Next available on {location.FirstAvailableSlot}</FormSubHeadingTypography>
                        <Link
                            href={location.LinkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FormSubHeadingTypography sx={{ color: `${theme.palette.primary.main} !important`, textDecorationLine: 'underline' }} >{location.AddressLine1}</FormSubHeadingTypography>
                            <FormSubHeadingTypography sx={{ color: `${theme.palette.primary.main} !important`, textDecorationLine: 'underline' }} >{location.AddressLine2}</FormSubHeadingTypography>
                        </Link>
                    </Box>
                    <FormSubHeadingTypography sx={{ mb: 1 }}>{location.Mobile}</FormSubHeadingTypography>
                    <Button variant="contained" color="primary"
                        onClick={() => submit(location.TreatmentLocationId, location.LocationName)}>
                        CLICK TO SCHEDULE
                    </Button>
                </CardContent>
            </Card>
        </Grid >
    );
};

export default AddressCard;