import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { StyledDialog, CircularBox } from "../CustomElements";
import {
  FormHeadingTypography,
  FormSubHeadingTypography,
} from "../CustomElements";
import { useTheme } from "@mui/material/styles";
import Link from "@mui/material/Link";

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  isNew: boolean;
  date: string;
  location: string;
  address: string;
  address1?: string;
  address2?: string;
  custom1?: string;
  custom2?: string;
  custom3?: string;
}

const SuccessModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  isNew,
  date,
  location,
  address,
  address1,
  address2,
  custom1,
  custom2,
  custom3,
}) => {
  const theme = useTheme();
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(
    `${address1 || ""} ${address2 || ""}`
  )}`;

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>
        <FormHeadingTypography sx={{ textAlign: "center" }}>
          Thank You!
        </FormHeadingTypography>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <CircularBox sx={{ mb: 2 }}>
            <ThumbUpIcon
              style={{ color: "#FFFFFF", width: "47px", height: "41px" }}
            />
          </CircularBox>
          <FormSubHeadingTypography>
            {isNew
              ? "Your appointment was successfully scheduled for:"
              : "There is already an appointment scheduled for:"}
          </FormSubHeadingTypography>
          <FormSubHeadingTypography sx={{ fontWeight: "700 !important" }}>
            {date}
          </FormSubHeadingTypography>
          <FormSubHeadingTypography>
            at the following location:
          </FormSubHeadingTypography>
          <FormSubHeadingTypography sx={{ fontWeight: "700 !important" }}>
            {location}
          </FormSubHeadingTypography>
          <FormSubHeadingTypography
            sx={{ color: `${theme.palette.primary.main} !important` }}
          >
             <Link
            href={googleMapsUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: `${theme.palette.primary.main} !important`, textDecoration: 'underline' }}
          >
            {address1 && <Typography>{address1}{" "}{address2}</Typography>}
            {/* {address2 && <Typography></Typography>} */}
          </Link>
            {/* "https://www.google.com/maps?q={Uri.EscapeDataString("" + treatmentlocation.Result.AddressLine1 + " " + treatmentlocation.Result.AddressLine2 + "")}"; */}
            {/* {address} */}
          </FormSubHeadingTypography>
          <FormSubHeadingTypography>
            {/* ALL PATIENTS must check in at the lobby. */}
            {custom1}
          </FormSubHeadingTypography>
          <FormSubHeadingTypography sx={{ fontWeight: "700 !important" }}>
            {/* Inform security you have an appointment with All Care Therapist
            Suite 600. */}
              {custom2}
          </FormSubHeadingTypography>
          <FormSubHeadingTypography>
          {custom3}
            {/* Street parking or valet parking, every 15 minutes $3. */}
          </FormSubHeadingTypography>
          <FormSubHeadingTypography
            sx={{ color: `${theme.palette.error.main} !important` }}
          >
            Please arrive at least 30 minutes prior to your appointment to allow
            for parking and registration.
          </FormSubHeadingTypography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose} variant="contained" color="primary">
          OK
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SuccessModal;
