import * as React from 'react';
import { Transition } from 'react-transition-group';
import { styled } from '@mui/system';
import { CheckRounded, ErrorOutline, WarningAmber } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/base/Snackbar';
import { SnackbarCloseReason } from '@mui/base/useSnackbar';

import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_NOTIFICATION } from '../../redux/slices/notificationSlice'

interface NotificationState {
    show: boolean;
    type: 'success' | 'error' | 'warning';
    head: string;
    message: string;
}

export default function UnstyledSnackbarIntroduction() {
    const selector = useSelector((state: { showNotification: NotificationState }) => state.showNotification)
    const dispatch = useDispatch()
    const handleCloseToast = () => {
        dispatch(CLOSE_NOTIFICATION())
    }
    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout
        if (selector.show) {
            timeoutId = setTimeout(() => {
                dispatch(CLOSE_NOTIFICATION())
            }, 5000)
        }
        return () => {
            clearTimeout(timeoutId)
        }
    }, [selector.show, dispatch])

    const [exited, setExited] = React.useState(true);
    const nodeRef = React.useRef(null);

    const handleClose = (_: any, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        handleCloseToast()
    };

    const handleOnEnter = () => {
        setExited(false);
    };

    const handleOnExited = () => {
        setExited(true);
    };
    return (
        <React.Fragment>
            <StyledSnackbar
                autoHideDuration={5000}
                open={true}
                onClose={handleClose}
                exited={exited}
            >
                <Transition
                    timeout={{ enter: 400, exit: 400 }}
                    in={true}
                    appear
                    unmountOnExit
                    onEnter={handleOnEnter}
                    onExited={handleOnExited}
                    nodeRef={nodeRef}
                >
                    {(status) => (
                        <SnackbarContent
                            style={{
                                transform: positioningStyles[status],
                                transition: 'transform 300ms ease',
                                backgroundColor: bg[selector.type]
                            }}
                            ref={nodeRef}

                        >
                            {icons[selector.type]}
                            <div className="snackbar-message">
                                <p className="snackbar-title">{selector.head}</p>
                                <p className="snackbar-description">
                                    {selector.message}
                                </p>
                            </div>
                            <CloseIcon onClick={handleClose} className="snackbar-close-icon" />
                        </SnackbarContent>
                    )}
                </Transition>
            </StyledSnackbar>
        </React.Fragment>
    );
}

const icons: { [key: string]: JSX.Element } = {
    success: <CheckRounded sx={{ color: 'white', flexShrink: 0, width: '22px', height: '22px' }} />,
    error: <ErrorOutline sx={{ color: 'white', flexShrink: 0, width: '22px', height: '22px' }} />,
    warning: <WarningAmber sx={{ color: 'white', flexShrink: 0, width: '22px', height: '22px' }} />,
};

const bg = {
    success: '#03f39e',
    error: '#EF5350',
    warning: '#f9c851',
}

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const blue = {
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  z-index: 5500;
  display: flex;
  top: 16px;
  right: 16px;
  max-width: 460px;
  min-width: 300px;
`;

const SnackbarContent = styled('div')(
    ({ theme }) => `
  display: flex;
  gap: 8px;
  overflow: hidden;
  background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border-radius: 8px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: ${theme.palette.mode === 'dark'
            ? `0 2px 16px rgba(0,0,0, 0.5)`
            : `0 2px 16px ${grey[200]}`
        };
  padding: 0.75rem;
  color: ${theme.palette.mode === 'dark' ? grey[50] : "#FFF"};
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;

  & .snackbar-message {
    flex: 1 1 0%;
    max-width: 100%;
  }

  & .snackbar-title {
    margin: 0;
    line-height: 1.5rem;
    margin-right: 0.5rem;
    font-size: 16px;
  }

  & .snackbar-description {
    margin: 0;
    line-height: 1.5rem;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.palette.mode === 'dark' ? grey[400] : "#FFF"};
  }

  & .snackbar-close-icon {
    cursor: pointer;
    flex-shrink: 0;
    padding: 2px;
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }
  `,
);

const positioningStyles = {
    entering: 'translateX(0)',
    entered: 'translateX(0)',
    exiting: 'translateX(500px)',
    exited: 'translateX(500px)',
    unmounted: 'translateX(500px)',
};

