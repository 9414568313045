import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../slice/index';
import { validateUserInfoForm, saveUserInfo } from '../../utils';
import { Errors } from '../../types';
import { HIDE_LOADER, SHOW_LOADER } from '../../../../redux/slices/loaderSlice';
import { userInfoSchema } from '../../schema';
import { Gender } from "../../../../utils/constant";
import { User } from '../../../dashboard/slice';
interface UserInfoFormProps {
    handleNext: () => void;
}

const UserInfoForm: React.FC<UserInfoFormProps> = ({ handleNext }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<Errors>({});

    // const { firstName, lastName, gender, dob, otherGender, decryptApiData, clientID } = useSelector((state: any) => state.profileReducer);
    const { decryptApiData, selectedClient } = useSelector((state: any) => state.UserReducer);
    const { Clientid: clientID } = useSelector((state: any) => state.UserReducer?.selectedClient);
    const { FirstName: firstName, LastName: lastName, DOB: dob, Sex: gender, OtherGender: otherGender } = useSelector((state: any) => state.UserReducer.selectedClient);
    const [formData, setFormData] = useState({ firstName, lastName, gender, dob, otherGender }); //loading into local state to avoid re-rendering andmake it fast

    useEffect(() => {
        setFormData({ firstName, lastName, gender, dob, otherGender });
    }, [firstName, lastName, gender, dob, otherGender]);

    const handleDateChange = (newValue: moment.Moment | null) => {
        const momentDate = newValue ? moment(newValue) : null;
        dispatch(Profile({ dob: momentDate }))
    };
    const updateVal = (e: React.ChangeEvent<HTMLInputElement>) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const handleSubmit = async () => {
        try {
            dispatch(SHOW_LOADER());
            await validateUserInfoForm(userInfoSchema, formData, setErrors);
            const payload = {
                ...formData,
                clientBioUpdateAction: 1,
                clientid: parseInt(decryptApiData.S === "8" ? decryptApiData?.ID : clientID),
                updateBy: parseInt(decryptApiData?.ID),
            }
            await saveUserInfo(payload);
            // dispatch(Profile({ ...formData }))
            dispatch(User({
                selectedClient: {
                    ...selectedClient,
                    FirstName: formData.firstName,
                    LastName: formData.lastName,
                    DOB: formData.dob,
                    OtherGender: formData.otherGender,
                }
            }))
            handleNext();
        }
        catch (e) {
            console.log(e, "---eee")
        }
        finally {
            dispatch(HIDE_LOADER());
        }
    }
    return (
        <Grid container spacing={3} style={{ padding: "24px" }} md={10} >
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Last name"
                    variant="outlined"
                    name="lastName"
                    fullWidth
                    value={formData.lastName}
                    required
                    error={!!errors.lastName}
                    onChange={updateVal}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="First name"
                    variant="outlined"
                    name="firstName"
                    fullWidth
                    value={formData.firstName}
                    required
                    error={!!errors.firstName}
                    onChange={updateVal}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Date of birth"
                        name="dob"
                        value={formData.dob ? moment(formData.dob) : null}
                        disableFuture
                        slotProps={{
                            textField: {
                                placeholder: 'Select a date',
                                disabled: true,
                                error: !!errors.dob,
                                sx: {
                                    '&:focus': {
                                        backgroundColor: 'lightblue',
                                    },
                                    width: "-webkit-fill-available",
                                },
                            },
                        }}
                        onChange={handleDateChange}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Gender"
                    variant="outlined"
                    name="gender"
                    fullWidth
                    select
                    value={formData.gender}
                    onChange={updateVal}
                    required
                    error={!!errors.gender}
                >
                    <MenuItem value="">Select</MenuItem>
                    {
                        Gender.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                    }
                </TextField>
                {
                    formData.gender === 5 &&
                    <TextField
                        label="Other"
                        variant="outlined"
                        name="otherGender"
                        fullWidth
                        value={formData.otherGender}
                        onChange={updateVal}
                        required
                        error={!!errors.otherGender}
                        sx={{ mt: 1 }}
                    />
                }
            </Grid>
            <Grid item md={12} sx={{ display: "flex", justifyContent: 'center' }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ width: "100px" }} >
                    NEXT
                </Button>
            </Grid>
        </Grid>
    );
}

export default UserInfoForm;
