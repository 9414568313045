import { createSlice } from '@reduxjs/toolkit'


const userSlice = createSlice({
    name: 'user',
    initialState: {
        brandLogo: 'ACT.png',
        clientID: '',
        companyID: '',
        mobile: '',
        dob: '',
        zipCode: '',
        errorMsg: {},
        listOfClient: [],
        selectedClient: {},
        medicalHistory:{},
    },
    reducers: {
        User(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
        UpdateSelectedClient(state, action) {
            return {
                ...state,
                selectedClient: {...state.selectedClient,...action.payload}, 
            };
        },
       MedicalHistory(state, action) {
            return {
                ...state,
                medicalHistory: {...state.medicalHistory,...action.payload}, 
               // ...action.payload,
            };
        }
    },
});

export const { User,UpdateSelectedClient,MedicalHistory } = userSlice.actions
// this is not in used right now
export const selectUser = (state: { persistedReducers: { user: any; }; }) =>
    state.persistedReducers.user


export default userSlice.reducer
