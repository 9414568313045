import React from "react";
import { Grid, useMediaQuery, SelectChangeEvent } from "@mui/material";
import { MobileVerificationProps } from "./types";
import { useSelector, useDispatch } from "react-redux";
import {
  getClientsByPhoneDOB,
  getFutureVisitContent,
  getValidClientForEvalContent,
} from "../../utils";
import { getVisitDataAfterVisitCreateApi } from "../../api";
import LeftImageComponent from "../../../../components/FormComponent/LeftImageComponent";
import FormComponent from "./form";
import { User } from "../../slice";
import { validateUserInfoForm } from "../../utils";
import { mobileVerificationSchema } from "../../schema";
import moment from "moment";
import { SHOW_SUCCESS_MODEL } from "../../../../redux/slices/successModelSlice";
import { SHOW_NOTIFICATION } from "../../../../redux/slices/notificationSlice";
import dateFormat from "../../../../utils/dateFormat";
const MobileVerification: React.FC<MobileVerificationProps> = ({
  handleChange,
  handleNext,
}) => {
  const {
    mobile,
    brandLogo,
    dob,
    companyID,
    errorMsg,
    listOfClient,
    selectedClient,
    decryptApiData,
  } = useSelector((state: any) => state.UserReducer);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useDispatch();

  const validate = async () => {
    //companyID = decryptApiData.C;
  // alert(JSON.stringify( JSON.stringify(decryptApiData)));
   // return;
    const formData = { mobile, dob, selectedClient, listOfClient };
    // alert(JSON.stringify(formData))
    // return;
    try {
      if (listOfClient && listOfClient.length > 1) {
        await validateUserInfoForm(
          mobileVerificationSchema,
          formData,
          dispatch
        );
        const futureVisit = await getFutureVisitContent(  
          selectedClient.Clientid,
          moment().format("MM/DD/YYYY")
        );
        //console.log(futureVisit, "futureVisit");
        //alert(JSON.stringify(selectedClient.Clientid));
        // return;

        if (futureVisit?.data?.Result) {
          //alert(JSON.stringify(getVisitData.data.Result.ContactInfo.CustomField1))
          const getVisitData = await getVisitDataAfterVisitCreateApi(
            futureVisit?.data?.Result.TreatmentLocationId
          );
          //alert(JSON.stringify(getVisitData.data.Result.ContactInfo.AddressLine1))
          dispatch(
            SHOW_SUCCESS_MODEL({
              visiblity: true,
              isNew: false,
              date: dateFormat(
                futureVisit?.data?.Result?.ScheduledDateFrom
              ).toUpperCase(),
              location: getVisitData.data.Result.ContactInfo.LocationName,
              address:
                getVisitData.data.Result.ContactInfo.Address1 +
                getVisitData.data.Result.ContactInfo.Address2,
              address1: getVisitData.data.Result.ContactInfo.Address1,
              address2: getVisitData.data.Result.ContactInfo.Address2,
              custom1: getVisitData.data.Result.ContactInfo.CustomField1,
              custom2: getVisitData.data.Result.ContactInfo.CustomField2,
              custom3: getVisitData.data.Result.ContactInfo.CustomField3,
            })
          );
        } else {
          const ValidClient = await getValidClientForEvalContent(
            selectedClient.Clientid,
            "2"
          );

          if (ValidClient?.data?.ErrorMessage) {
            dispatch(
              SHOW_NOTIFICATION({
                message: ValidClient?.data?.ErrorMessage,
                type: "error",
                head: "Error !",
              })
            );
          } else {
           // alert("handleNext");
            
            handleNext();
          }
        }
      } else {
        //alert(JSON.stringify("companyID asas: " + companyID));
        //return;
        await validateUserInfoForm(
          mobileVerificationSchema,
          formData,
          dispatch
        );
        const clientData = await getClientsByPhoneDOB({
          mobile,
          dob,
          companyId: companyID,
          dispatch,
          handleNext,
        });
        //alert(JSON.stringify(clientData));
        //return;
        // const ValidClient = await getValidClientForEvalContent(
        //   selectedClient.Clientid,
        //   "2"
        // );

        // if (ValidClient?.data?.ErrorMessage) {
        //   dispatch(
        //     SHOW_NOTIFICATION({
        //       message: ValidClient?.data?.ErrorMessage,
        //       type: "error",
        //       head: "Error !",
        //     })
        //   );
        // } else {
        // const clientData = await getClientsByPhoneDOB({
        //   mobile,
        //   dob,
        //   companyId: companyID,
        //   dispatch,
        //   handleNext,
        // });
        // }
        //alert(JSON.stringify(clientData));
        //return;
        // const futureVisit = await getFutureVisitContent(
        //     selectedClient,
        //     moment().format("MM/DD/YYYY")
        //   );
        //   console.log(futureVisit, "futureVisit");
        //   dispatch(
        //     SHOW_SUCCESS_MODEL({
        //       visiblity: true,
        //       isNew: false,
        //       date:dateFormat( futureVisit?.data?.Result?.ScheduledDateFrom).toUpperCase(),
        //       location: futureVisit?.data?.Result?.TreatmentLocationName,
        //       address:  futureVisit?.data?.Result?.TreatmentLocationAddressLine1 +  futureVisit?.data?.Result?.TreatmentLocationAddressLine2,
        //     })
        //   );
        //handleNext();
      }
    } catch (e) {
      console.log("Error validate : ", e);
    }
  };

  const dateChange = (date: moment.Moment | null) => {
    if (errorMsg.dob) {
      dispatch(User({ errorMsg: {} }));
    }
    const event = {
      target: {
        name: "dob",
        value: date ? date.format("YYYY-MM-DD") : "",
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };
  const updateMoNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    //alert(e.target.value)
    //dispatch(User({[e.target.name]: e.target.value}));

    if (errorMsg.mobile) {
      dispatch(User({ errorMsg: {} }));
    }
    const event = {
      target: {
        name: "mobile",
        value: e.target.value ? e.target.value : "",
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };

  ///=> setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  const handleClientSelect = async (
    event: React.ChangeEvent<{ value: unknown }> | SelectChangeEvent<any>
  ) => {
    const selectedClientId = event.target.value;
    const selectedClient = listOfClient.find(
      (client: { Clientid: number }) => client.Clientid === selectedClientId
    );
    //alert(JSON.stringify(selectedClient))

    dispatch(
      User({
        clientID: selectedClientId,
        selectedClient: {
          ...selectedClient,
          DOB: selectedClient.DOB.split("T")[0],
        },
        zipCode: selectedClient?.ContactInfoDTO?.Zip,
      })
    );
    const futureVisit = await getFutureVisitContent(
      selectedClientId,
      moment().format("MM/DD/YYYY")
    );
    //console.log(futureVisit, "futureVisit");
    if (futureVisit?.data?.Result) {
      //alert(JSON.stringify(getVisitData.data.Result.ContactInfo.CustomField1))
      const getVisitData = await getVisitDataAfterVisitCreateApi(
        futureVisit?.data?.Result.TreatmentLocationId
      );
      //alert(JSON.stringify(getVisitData.data.Result.ContactInfo.Address2));
      //alert(JSON.stringify(getVisitData.data.Result.ContactInfo.CustomField1))
      dispatch(
        SHOW_SUCCESS_MODEL({
          visiblity: true,
          isNew: false,
          date: dateFormat(
            futureVisit?.data?.Result?.ScheduledDateFrom
          ).toUpperCase(),
          location: getVisitData.data.Result.ContactInfo.LocationName,
          address:
            getVisitData.data.Result.ContactInfo.Address1 +
            getVisitData.data.Result.ContactInfo.Address2,
          address1: getVisitData.data.Result.ContactInfo.Address1,
          address2: getVisitData.data.Result.ContactInfo.Address2,
          custom1: getVisitData.data.Result.ContactInfo.CustomField1,
          custom2: getVisitData.data.Result.ContactInfo.CustomField2,
          custom3: getVisitData.data.Result.ContactInfo.CustomField3,
        })
      );
    } else {
      const ValidClient = await getValidClientForEvalContent(
        selectedClientId,
        "2"
      );

      if (ValidClient?.data?.ErrorMessage) {
        dispatch(
          SHOW_NOTIFICATION({
            message: ValidClient?.data?.ErrorMessage,
            type: "error",
            head: "Error !",
          })
        );
      }
    }
  };

  return (
    <Grid
      container
      sx={{ display: "flex", alignItems: "center", height: "100%" }}
    >
      {isMobile ? (
        <FormComponent
          brandLogo={brandLogo}
          mobile={mobile}
          dateChange={dateChange}
          moNoChange={updateMoNo}
          validate={validate}
          errorMsg={errorMsg}
          listOfClient={listOfClient}
          selectedClient={selectedClient}
          handleClientSelect={handleClientSelect}
        />
      ) : (
        <>
          <LeftImageComponent />
          <FormComponent
            brandLogo={brandLogo}
            mobile={mobile}
            moNoChange={updateMoNo}
            dateChange={dateChange}
            validate={validate}
            errorMsg={errorMsg}
            listOfClient={listOfClient}
            selectedClient={selectedClient}
            handleClientSelect={handleClientSelect}
          />
        </>
      )}
    </Grid>
  );
};

export default MobileVerification;
