import * as Yup from 'yup';


export const TherapistTypeSchema = Yup.object().shape({
    therapistType: Yup.string().required(),
});
export const RequireAssistanceSchema = Yup.object().shape({
    requireAssistance: Yup.string().required(),
});
export const DiagnosisIssueSchema = Yup.object().shape({
    diagnosisIssue: Yup.string().required(),
});



