
  const TherapistType = [
    { id: 'None', label: 'None' },
    { id: 'Audiologist', label: 'Audiologist' },
    { id: 'Endocrinologist', label: 'Endocrinologist' },
    { id: 'Gastroenterologist', label: 'Gastroenterologist' },
    { id: 'Neurologist', label: 'Neurologist' },
    { id: 'Occupational Therapist', label: 'Occupational Therapist' },
    { id: 'Oncologist', label: 'Oncologist' },
    { id: 'Orthopedist', label: 'Orthopedist' },
    { id: 'Physical Therapist', label: 'Physical Therapist' },
    { id: 'Psychologist', label: 'Psychologist' },
    { id: 'Speech Therapist', label: 'Speech Therapist' },
    
  ]
  const TherapistTypeOptions = TherapistType.map((item) => ({
    id: item.id,
    label: item.label,
  }))
  
  const DiagnosisIssue = [
    { id: 'None', label: 'None' },
    { id: 'Pacemaker', label: 'Pacemaker' },
    { id: 'Currently Pregnant', label: 'Currently Pregnant' },
    { id: 'Diabetes Mellitus', label: 'Diabetes Mellitus' },
    { id: 'Spinal Cord Injury', label: 'Spinal Cord Injury' },
    { id: 'Shortness of Breath / Chest Pain', label: 'Shortness of Breath / Chest Pain' },
    { id: 'High Blood Pressure', label: 'High Blood Pressure' },
    { id: 'Blood Clots / DVT / Embolism', label: 'Blood Clots / DVT / Embolism' },
    { id: 'Cancer / Chemotherapy / Radiation', label: 'Cancer / Chemotherapy / Radiation' },
    { id: 'Coronary Heart Disease / Angina', label: 'Coronary Heart Disease / Angina' },
    { id: 'Stroke / Mini-stroke (TIA)', label: 'Stroke / Mini-stroke (TIA)' },
    { id: 'Peripheral Vascular Disease', label: 'Peripheral Vascular Disease' },
    { id: 'Concussion / Traumatic Brain Injury', label: 'Concussion / Traumatic Brain Injury' },
  ]
  const DiagnosisIssueOptions = DiagnosisIssue.map((item) => ({
    id: item.id,
    label: item.label,
  }))

  const RequireAssistance = [
    { id: 'None', label: 'None' },
    { id: 'Showering/Personal Hygiene', label: 'Showering/Personal Hygiene' },
    { id: 'Financial Management', label: 'Financial Management' },
    { id: 'Phone Calls/Texting', label: 'Phone Calls/Texting' },
    { id: 'Eating', label: 'Eating' },
    { id: 'Interpersonal Relationships', label: 'Interpersonal Relationships' },
    { id: 'Household Management', label: 'Household Management' },
    { id: 'Grocery Shopping', label: 'Grocery Shopping' },
    { id: 'Cooking', label: 'Cooking' },
    { id: 'Employment/Work Mgmt', label: 'Employment/Work Mgmt' },
    { id: 'Transportation/Driving', label: 'Transportation/Driving' },
    { id: 'Dressing', label: 'Dressing' },
    { id: 'Managing Appointments', label: 'Managing Appointments' },
    { id: 'Walking/Mobility', label: 'Walking/Mobility' },
    { id: 'Memory', label: 'Memory' },
    { id: 'Toileting', label: 'Toileting' },
    { id: 'Telling Time', label: 'Telling Time' },
    { id: 'Writing/Typing', label: 'Writing/Typing' },
  ]
  const RequireAssistanceOptions = RequireAssistance.map((item) => ({
    id: item.id,
    label: item.label,
  }))
  
  
  export {
    TherapistTypeOptions,
    DiagnosisIssueOptions,
    RequireAssistanceOptions,    
  }
  