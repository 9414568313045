import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SuccessModelState {
    visiblity: boolean;
    isNew: boolean;
    date: string;
    location: string;
    address: string;
}

const initialState: SuccessModelState = {
    visiblity: false,
    isNew: false,
    date: '',
    location: '',
    address: '',
};

const successModelSlice = createSlice({
    name: 'successModel',
    initialState,
    reducers: {
        SHOW_SUCCESS_MODEL(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },
        HIDE_SUCCESS_MODEL(state) {
            return {
                ...state,
                visiblity: false,
            };
        },
    },
});

export const { SHOW_SUCCESS_MODEL, HIDE_SUCCESS_MODEL } = successModelSlice.actions;

export default successModelSlice.reducer;