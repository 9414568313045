import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "../../slice/index";
import { validateUserInfoForm, saveUserAddress } from "../../utils";
import { Errors } from "../../types";
import { HIDE_LOADER, SHOW_LOADER } from "../../../../redux/slices/loaderSlice";
import { userAddressSchema } from "../../schema";
import { getStateListApi } from "../../api/index";
import { User } from "../../../dashboard/slice";
import moment from "moment";

interface UserInfoFormProps {
  handleNext: () => void;
  handlePrev: () => void;
  showConsentStep: boolean;
}

const UserAddressForm: React.FC<UserInfoFormProps> = ({
  handlePrev,
  handleNext,
  showConsentStep,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<Errors>({});
  const [stateList, setStateList] = useState<{ Value: string; Text: string }[]>(
    []
  );

  // const { addressLine1, addressLine2, city, state, zipCode, decryptApiData, clientID } = useSelector((state: any) => state.profileReducer);
  const { decryptApiData, selectedClient } = useSelector(
    (state: any) => state.UserReducer
  );
  const { Clientid: clientID } = useSelector(
    (state: any) => state.UserReducer?.selectedClient
  );

  const {
    Address1: addressLine1,
    Address2: addressLine2,
    City: city,
    State: state,
    Zip: zipCode,
  } = useSelector(
    (state: any) => state.UserReducer.selectedClient.ContactInfoDTO
  );
  const [formData, setFormData] = useState({
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
  }); //loading into local state to avoid re-rendering andmake it fast

  useEffect(() => {
    setFormData({
      addressLine1,
      addressLine2,
      state,
      zipCode,
      city: city
        ?.toLowerCase()
        .replace(/\b\w/g, (char: string) => char.toUpperCase()),
    });
  }, [addressLine1, addressLine2, city, state, zipCode]);

  useEffect(() => {
    getStateListApi()
      .then((res) => setStateList(res.data.ListResult))
      .catch((err) => setStateList([]));
  }, []);

  const updateVal = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleZipCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\D/g, "");
    updateVal(e);
  };
  const handleSubmit = async () => {
    try {
      dispatch(SHOW_LOADER());
      await validateUserInfoForm(userAddressSchema, formData, setErrors);
      const payload = {
        ...formData,
        clientBioUpdateAction: 3,
        demographicUpdatedon: decryptApiData.S === "8" ? null :  moment().format("MM/DD/YYYY"),
        clientid: parseInt(
          decryptApiData.S === "8" ? decryptApiData?.ID : clientID
        ),
        updateBy: parseInt(decryptApiData?.ID),
      };
      await saveUserAddress(payload);
      // dispatch(Profile({ ...formData }))
      dispatch(
        User({
          selectedClient: {
            ...selectedClient,
            ContactInfoDTO: {
              ...selectedClient.ContactInfoDTO,
              Address1: formData.addressLine1,
              Address2: formData.addressLine2,
              State: formData.state,
              Zip: formData.zipCode,
              City: formData.city,
            },
          },
        })
      );
      handleNext();
    } catch (e) {
      console.log(e, "---eee");
    } finally {
      dispatch(HIDE_LOADER());
    }
  };
  return (
    <Grid container spacing={3} style={{ padding: "24px" }} md={10}>
      <Grid item sm={12}>
        <TextField
          label="Address Line 1"
          variant="outlined"
          name="addressLine1"
          fullWidth
          value={formData.addressLine1}
          required
          error={!!errors.addressLine1}
          onChange={updateVal}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          label="Address Line 2"
          variant="outlined"
          name="addressLine2"
          fullWidth
          value={formData.addressLine2}
          error={!!errors.addressLine2}
          // helperText={errors.firstName}
          onChange={updateVal}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          label="State"
          variant="outlined"
          name="state"
          fullWidth
          select
          value={formData.state}
          required
          error={!!errors.state}
          onChange={updateVal}
        >
          <MenuItem value="">Please Select State</MenuItem>
          {stateList.length > 0 &&
            stateList.map((item, index) => (
              <MenuItem key={index} value={item.Value}>
                {item.Text}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item sm={4}>
        <TextField
          label="City"
          variant="outlined"
          name="city"
          fullWidth
          value={formData.city}
          required
          error={!!errors.city}
          onChange={updateVal}
        />
      </Grid>
      <Grid item sm={4}>
        <TextField
          label="Zip Code"
          variant="outlined"
          name="zipCode"
          fullWidth
          value={formData.zipCode}
          required
          error={!!errors.zipCode}
          onChange={handleZipCode}
          inputProps={{ maxLength: 5 }}
        />
      </Grid>
      <Grid
        item
        md={12}
        sx={{ display: "flex", justifyContent: "center", gap: 3 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrev}
          sx={{ width: "100px" }}
        >
          PREVIOUS
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: "100px" }}
        >
         {showConsentStep===false ? "SAVE" : "NEXT"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UserAddressForm;
