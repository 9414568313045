import { Box } from '@mui/system';
const Header = ({ width }: any) => {
  const viewBox = `0 0 ${width} 16`;
  const halfWidth = width / 2;

  return (
    <header style={{ width: '100%', zIndex: 1000, height: "18px" }}>
      <Box sx={{ display: 'flex' }}>
        <svg width="100%" height="16" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d={`M0 0H${halfWidth}L${halfWidth - 10} 16H0V0Z`} fill="#0A78B8" />
          <path d={`M${halfWidth} 16L0 16L10 0L${halfWidth} 0V16Z`} transform={`translate(${halfWidth}, 0)`} fill="#00A1C1" />
        </svg>
      </Box>
    </header>
  );
};

export default Header;
