import { Grid } from "@mui/material";
import { CommonImage } from '../../../assets/images';

const LeftImageComponent = () => {
    return (
        <Grid item md={6} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'flex-start', md: 'flex-end' },
            alignItems: 'center',
            height: '100%',
            background: '#fff',
        }}>
            <img
                src={CommonImage}
                alt="image"
                className='common-image'
            />
        </Grid>
    );
};

export default LeftImageComponent;