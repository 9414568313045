import * as Yup from 'yup';

export const userInfoSchema = Yup.object().shape({
    lastName: Yup.string().required(),
    firstName: Yup.string().required(),
    dob: Yup.date().required().nullable(),
    gender: Yup.string().required(),
    otherGender: Yup.string().nullable().test({
        name: 'is-other',
        test: function (value) {
            const { gender } = this.parent; // Access gender field from the parent context
            if (gender === '5') {
                return value !== null && value !== undefined && value !== '';
            }
            return true;
        },
    }),
});

export const userAddressSchema = Yup.object().shape({
    addressLine1: Yup.string().required(),
    addressLine2: Yup.string(),//.required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string().required().min(5).max(5),
});

export const userContactSchema = Yup.object().shape({
    mobile: Yup.string().required().min(10).max(10),
    primaryEmail: Yup.string().email(),//.required(),
});

export const userConsentSchema = Yup.object().shape({
    acknowledge: Yup.boolean()
        .oneOf([true], 'You must acknowledge')
        .required(),
    consentBy: Yup.string().required(),
})