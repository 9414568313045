import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import { SlotSelectProps } from './types';
import { useSelector } from 'react-redux';
import { createAppointment } from '../../utils';
import { Company, VisitType, TreatmentType, POS } from '../../../../utils/constant';
import dateFormat from '../../../../utils/dateFormat';
import { FormHeadingTypography, HeadBar } from '../../../../components/FormComponent/CustomElements';
import { useTheme } from '@mui/material/styles';
import { SHOW_LOADER, HIDE_LOADER } from '../../../../redux/slices/loaderSlice';
import { SHOW_NOTIFICATION } from '../../../../redux/slices/notificationSlice'
import { useDispatch } from 'react-redux';
import { SHOW_SUCCESS_MODEL } from '../../../../redux/slices/successModelSlice'
import { User } from '../../slice';

const SlotSelect: React.FC<SlotSelectProps> = ({ handleSubmit, handlePrev }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 7
    const { slotList, decryptApiData } = useSelector((state: any) => state.UserReducer)
    const { Clientid: clientID, BrandLogo: brandLogo, CompanyId: companyID } = useSelector((state: any) => state.UserReducer?.selectedClient);

    const totalPages = Math.ceil(slotList.list.length / itemsPerPage);

    const handleSlotClick = async (slot: any) => {
        try {
            dispatch(SHOW_LOADER())
            const payload = {
                ClientId: clientID,
                CompanyId: companyID,
                TherapistId: slot.userId,
                ScheduledDateFrom: slot.Start,
                ScheduledDateTo: slot.End,
                CreatedBy: decryptApiData.ID,
                CreateSource: decryptApiData.S,
                VisitTypeId: companyID === Company.SLPTele ? VisitType.TestSession : VisitType.Eval,
                TreatmentLocationId: slotList.treatmetnLocationId,
                TreatmentTypeId: TreatmentType.PT,
                POS: POS.Clinic
            }
            const res = await createAppointment(payload)
            if (res && res.status === 200) {
                ///This will update the current selected slot in redux
                dispatch(
                    User({
                      slotDetails: {
                        ...slot,
                      },
                      treatmentLocationDetail: res.data.Result,
                    })
                  );
              //alert(JSON.stringify(res.data.Result))
                dispatch(SHOW_NOTIFICATION({ message: 'Visit Created SuccessFully', type: 'success', head: 'Success !' }))
                dispatch(SHOW_SUCCESS_MODEL({
                    visiblity: false,
                    isNew: true,
                    date: dateFormat(slot.Start).toUpperCase(),
                    location: res?.data?.Result?.ContactInfo?.City,
                    address: res?.data?.Result?.Address
                }))
            }
            handleSubmit();
        }
        catch (err) {
            console.log(err)
        }
        finally {
            dispatch(HIDE_LOADER())
        };
    }
    const next = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage((prev) => prev + 1)
        }
    }
    const previous = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }
    return (
        <Container>
            <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: "center", mb: 3 }}>
                <img
                    src={require(`../../../../assets/images/${brandLogo}`)}
                    alt="Brand"
                    className='brand-image'
                />
            </Box>
            <HeadBar sx={{ mb: 2 }}>
                <FormHeadingTypography sx={{ color: '#fff !important', fontWeight: '500 !important' }}>
                    {slotList.name}
                </FormHeadingTypography>
            </HeadBar>

            {/* Appointment Selection Section */}
            <Box sx={{ mb: 3 }}>
                <FormHeadingTypography sx={{ mb: 3, ml: 2 }}>
                    Select a date/time to schedule your appointment:
                </FormHeadingTypography>
                <Grid spacing={2} justifyContent="center" sx={{
                    gap: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'flexStart'
                }}>
                    {slotList.list.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((item: { Start: string }, index: number) => (
                        <Grid item key={index}>
                            <Button
                                variant='outlined'
                                sx={{ width: "225px" }}
                                onClick={() => handleSlotClick(item)}
                            >
                                {dateFormat(item.Start).toUpperCase()}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Navigation Buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button variant="outlined" color="secondary"
                    sx={{ width: "100px", borderColor: "rgba(0, 161, 193, 0.50) !important", color: theme.palette.primary.main }}
                    onClick={handlePrev}>
                    CANCEL
                </Button>
                <Button variant="contained" color="primary" onClick={previous} sx={{ width: "100px" }}>PREVIOUS</Button>
                <Button variant="contained" color="primary" onClick={next} sx={{ width: "100px" }}>NEXT</Button>
            </Box>
        </Container >
    );
}

export default SlotSelect;
