import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_LOADER, HIDE_LOADER } from "../../../../redux/slices/loaderSlice";
import { MedicalHistory } from "../../../dashboard/slice";
import { TherapistTypeOptions } from "../step1Form/options";
import { saveMedicalHistory, validateUserInfoForm } from "../../utils";
import { TherapistTypeSchema } from "../../schema";
import { Errors } from "../../types";

interface UserInfoFormProps {
  handleNext: () => void;
}

const Step1Form: React.FC<UserInfoFormProps> = ({ handleNext }) => {
  const dispatch = useDispatch();
  const { decryptApiData, selectedClient, medicalHistory,treatmentLocationDetail } = useSelector(
    (state: any) => state.UserReducer
  );
  const { Clientid: clientID } = selectedClient || {};

  const [errors, setErrors] = useState<Errors>({});

  const { TherapistType: therapistType } = medicalHistory || {};

  const { VisitId } = treatmentLocationDetail || {};

  // Initialize formData with therapistType
  const [formData, setFormData] = useState({
    therapistType: therapistType || "",
  });

  // Convert comma-separated string to an array of selected therapist types
  const [selectedTherapistTypes, setSelectedTherapistTypes] = useState<
    string[]
  >(therapistType ? therapistType.split(";") : []);

  useEffect(() => {
    setSelectedTherapistTypes(therapistType ? therapistType.split(";") : []);
  }, [therapistType]);
  const handleChange = (event: { target: { name: string; checked: boolean } }) => {
    const { name, checked } = event.target;
  
    // Check if "None" is selected
    if (name === "None" && checked) {
      // Clear all other options if "None" is checked
      setSelectedTherapistTypes(["None"]);
      setFormData((prev) => ({
        ...prev,
        therapistType: "None", // Set only "none" in form data
      }));
    } else {
      setSelectedTherapistTypes((prevItems) => {
        // If "None" is already selected and another option is checked, clear "None"
        if (prevItems.includes("None")) {
          return checked ? [name] : prevItems.filter((item) => item !== "None");
        }
  
        if (checked) {
          return [...prevItems, name]; // Add item to the array
        } else {
          return prevItems.filter((item) => item !== name); // Remove item from the array
        }
      });
  
      // Update formData with the updated selected items as a comma-separated string
      setFormData((prev) => ({
        ...prev,
        therapistType: checked
          ? [...selectedTherapistTypes.filter((item) => item !== "None"), name].join(";")
          : selectedTherapistTypes.filter((item) => item !== name).join(";"),
      }));
    }
  };
  
  // const handleChange = (event: {
  //   target: { name: string; checked: boolean };
  // }) => {
  //   const { name, checked } = event.target;

  //   setSelectedTherapistTypes((prevItems) => {
  //     if (checked) {
  //       return [...prevItems, name]; // Add item to the array
  //     } else {
  //       return prevItems.filter((item) => item !== name); // Remove item from the array
  //     }
  //   });

  //   // Update formData with the updated selected items as a comma-separated string
  //   setFormData((prev) => ({
  //     ...prev,
  //     therapistType: checked
  //       ? [...selectedTherapistTypes, name].join(";")
  //       : selectedTherapistTypes.filter((item) => item !== name).join(";"),
  //   }));
  // };

  const handleSubmit = async () => {
    try {
      //alert(JSON.stringify(formData));
      //return;
      dispatch(SHOW_LOADER());
      await validateUserInfoForm(TherapistTypeSchema, formData, setErrors);
      const payload = {
        ...formData,
        VisitId: VisitId,
        OnbordingEventType: 2,
        clientid: parseInt(
          decryptApiData.S === "8" ? decryptApiData?.ID : clientID
        ),
        updateBy: parseInt(decryptApiData?.ID),
      };

     // alert(JSON.stringify(payload));
      //return;
      await saveMedicalHistory(payload);
      // Dispatch the updated therapistType to Redux
      dispatch(
        MedicalHistory({
          TherapistType: formData.therapistType,
        })
      );

      handleNext();
    } catch (e) {
      console.error(e, "--- Error in form submission");
    } finally {
      dispatch(HIDE_LOADER());
    }
  };

  const options = TherapistTypeOptions;

  return (
    <Grid
      container
      spacing={0}
      style={{ marginLeft: "200px", padding: "24px" }}
      md={10}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "17px" }}
        >
          Please select the specialist you are currently being treated by.
        </Typography>
      </Grid>

      {options.map((item, index) => (
        <Grid item xs={12} sm={4} key={item.id}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedTherapistTypes.includes(item.id)}
                    onChange={handleChange}
                    name={item.id}
                    color="primary"
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      ))}

      {errors.therapistType && (
        <Grid item xs={12} textAlign={"center"}>
          <Typography color="error" variant="h6">
            Please select at least one option.
          </Typography>
        </Grid>
      )}
      <Grid
        item
        md={12}
        sx={{ display: "flex", justifyContent: "center", gap: 3 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: "100px" }}
        >
          NEXT
        </Button>
      </Grid>
    </Grid>
  );
};

export default Step1Form;
