const TreatmentType = {
    ST: 1,
    PT: 2,
    OT: 3
}

const Company = {
    SLPTele: 1,
    // SLPTeleTraining,
    // AllCare,
    // Mesa,
    // BottleStee,
    // CatalyticS
}

const VisitType = {
    //Billable
    Eval: 1,
    FollowUp: 2,
    ReEval: 3,
    Discharge: 4,
    OneOnOne: 16,

    //Non-billable
    HoldAppointmentTime: 5,
    NotAvailable: 6,
    TimeOff: 7,
    PersonalTime: 8,
    Holiday: 9,
    TestSession: 10,
    OfficeDC: 11,
    Hold: 12,
    EvalBlock: 13,
    Supervision: 14,
    TeamMeeting: 15,
    AdminTime: 17
}

const POS = {
    Tele: 1,
    Clinic: 2,
    Home: 3
}
const VisitSource = {
    TherapistLeave: 1,
    ChatbotRequest: 2,
    SLPAppSchedular: 3,
    ClientRequest: 4,
    SchedulerPage: 5,
    ManageSession: 6,
    ExitGroupSession: 7,
    ClientEval: 8,
    SchedularEval: 9,
    SLPForms: 10,
    ClientMyschedule: 11,
    ClientMakeUpsession: 12,
    BioUpdateByClient: 13
}

const Gender = [
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
    { label: "Transgender", value: 3 },
    { label: "Prefer not to say", value: 4 },
    { label: "Other", value: 5 },
]
export {
    TreatmentType,
    Company,
    VisitType,
    POS,
    VisitSource,
    Gender
}