import { createSlice } from '@reduxjs/toolkit'

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        firstName: '',
        lastName: '',
        gender: '',
        dob: '',

        primaryEmail: '',
        mobile: '',
        textNotification: false,
        emailNotification: false,

        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',

        acknowledge: false,
        consentBy: '',

        brandLogo: '',
        clientID: '',
        companyID: '',
        errorMsg: {},
    },
    reducers: {
        Profile(state, action) {
            return {
                ...state,
                ...action.payload,
            };
        },

    },
});

export const { Profile } = profileSlice.actions

export default profileSlice.reducer
