import { createSlice } from '@reduxjs/toolkit'

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    show: false,
    message: '',
    type: '',
    head: '',
  },
  reducers: {
    SHOW_NOTIFICATION(state, action) {
      state.show = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.head = action.payload.head;
    },
    CLOSE_NOTIFICATION(state) {
      state.show = false;
      state.message = '';
      state.type = '';
      state.head = '';
    },
  },
});

export const { SHOW_NOTIFICATION, CLOSE_NOTIFICATION } = notificationSlice.actions
export const selectNotification = (state: { showNotification: any; }) => state.showNotification
export default notificationSlice.reducer
