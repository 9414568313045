// theme.ts
import { createTheme, ThemeOptions } from '@mui/material/styles';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#00A1C1',
      contrastText: '#FFFFFF',
      dark: '#00768D',
    },
    secondary: {
      main: '#00405F',
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#D32F2F',
      light: '#EF5350',
    },
    warning: {
      main: '#ff9800',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
