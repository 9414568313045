import { CommonArgs } from "../type";
import { HIDE_LOADER, SHOW_LOADER } from "../../../redux/slices/loaderSlice";
import { User, UpdateSelectedClient } from "../../dashboard/slice";
import {
  validateLinkApi,
  getClientDataByIdApi,
  getClientsByPhoneDOBApi,
  getLocationForTreatmentApi,
  getAvailableSlotsApi,
  createAppointmentApi,
  getFutureVisitApi,
  getValidClientForEval,
  getVisitDataAfterVisitCreateApi,
} from "../api";
import { LocationArgs } from "../../dashboard/type";
import { SHOW_NOTIFICATION } from "../../../redux/slices/notificationSlice";
import { SHOW_SUCCESS_MODEL } from "../../../redux/slices/successModelSlice";
import moment from "moment";
import * as Yup from "yup";
import { Gender } from "../../../utils/constant";
//import dateFormat from '../../../../utils/dateFormat';
import dateFormat from "../../../utils/dateFormat";

const getLinkData = async ({
  clientLink,
  dispatch,
  setError,
  navigate,
}: CommonArgs) => {
  dispatch(SHOW_LOADER());
  try {
    const res = await validateLinkApi(clientLink as string);

    const params = new URLSearchParams(res.data.Result);
    const obj = Object.fromEntries(params.entries());
    //obj.T ="1";
    if (!obj.T) {
      obj.T = "1";
    }
    dispatch(User({ companyID: obj.C, decryptApiData: obj }));
    //alert(JSON.stringify(obj));
    // check future visit
    // const futureVisit = await getFutureVisitContent(
    //   obj.ID,
    //   moment().format("MM/DD/YYYY")
    // );
    //console.log(futureVisit, "futureVisit");
    // dispatch(
    //   SHOW_SUCCESS_MODEL({
    //     visiblity: true,
    //     isNew: false,
    //     date:dateFormat( futureVisit?.data?.Result?.ScheduledDateFrom).toUpperCase(),
    //     location: futureVisit?.data?.Result?.TreatmentLocationName,
    //     address:  futureVisit?.data?.Result?.TreatmentLocationAddressLine1 +  futureVisit?.data?.Result?.TreatmentLocationAddressLine2,
    //   })
    // );
    if (obj.S === "8") {
      await getClientData({ clientID: obj.ID, dispatch, setError, navigate });
    } else if (obj.S === "9") {
      dispatch(HIDE_LOADER());
      //await getClientsByPhoneDOB({ mobile: obj.M, dob: obj.D, companyId: obj.C, dispatch, handleNext: navigate });
    }
  } catch (e) {
    setError && setError("Invalid Link");
    dispatch(HIDE_LOADER());
  }
};

const getClientData = async ({
  clientID,
  dispatch,
  setError,
  navigate,
}: CommonArgs) => {
  try {
    dispatch(SHOW_LOADER());
    const res = await getClientDataByIdApi(clientID as string);
    const payload = {
      brandLogo: res.data.Result.BrandLogo,
      mobile: res.data.Result.ContactInfoDTO.Mobile,
      companyID: res.data.Result.CompanyId,
      clientID: res.data.Result.Clientid,
      profileUpdated: res.data.Result.DemographicUpdatedon ? true : false,
    };
    dispatch(User(payload));
  } catch (e) {
    console.log(e, "e");
  } finally {
    dispatch(HIDE_LOADER());
  }
};

const getClientsByPhoneDOB = async ({
  mobile,
  dob,
  companyId,
  dispatch,
  handleNext,
}: CommonArgs) => {
  try {
    dispatch(SHOW_LOADER());
    const res = await getClientsByPhoneDOBApi(mobile!, dob!, companyId!);
    //console.log(res, "res.data.ListResult[0]")
    //alert(res.data.ListResult.length)
    if (res.data.ListResult.length === 0) {
      dispatch(
        SHOW_NOTIFICATION({
          message:
            "NO CLIENT EXISTS WITH GIVEN PHONE NUMBER AND DATE OF BIRTH.",
          type: "error",
          head: "Error !",
        })
      );
      dispatch(User({ listOfClient: [] }));
    }
    // Update more then one client
    else if (res.data.ListResult.length > 1) {
      dispatch(User({ listOfClient: res.data.ListResult }));
    } else {
      // Update one client pop up information
      if (handleNext) {
        dispatch(
          User({
            zipCode: res.data.ListResult[0].ContactInfoDTO.Zip,
            listOfClient: res.data.ListResult,
          })
        );
        ///Update selected client to Redux Store
        dispatch(
          UpdateSelectedClient({
            ...res.data.ListResult[0],
            DOB: res.data.ListResult[0].DOB.split("T")[0],
            Gender: Gender.find(
              (gender) => gender.label === res.data.ListResult[0].Gender
            )?.value,
          })
        );

        const futureVisit = await getFutureVisitContent(
          res.data.ListResult[0].Clientid,
          moment().format("MM/DD/YYYY")
        );
        //console.log(futureVisit, "futureVisit");
        if (futureVisit?.data?.Result) {
          //alert(JSON.stringify(getVisitData.data.Result.ContactInfo.CustomField1))
          const getVisitData = await getVisitDataAfterVisitCreateApi(
            futureVisit?.data?.Result.TreatmentLocationId
          );
          //alert(JSON.stringify(getVisitData.data.Result.ContactInfo.CustomField1))
          dispatch(
            SHOW_SUCCESS_MODEL({
              visiblity: true,
              isNew: false,
              date: dateFormat(
                futureVisit?.data?.Result?.ScheduledDateFrom
              ).toUpperCase(),
              location: getVisitData.data.Result.ContactInfo.LocationName,
              address:
                getVisitData.data.Result.ContactInfo.Address1 +
                getVisitData.data.Result.ContactInfo.Address2,
              address1: getVisitData.data.Result.ContactInfo.Address1,
              address2: getVisitData.data.Result.ContactInfo.Address2,
              custom1: getVisitData.data.Result.ContactInfo.CustomField1,
              custom2: getVisitData.data.Result.ContactInfo.CustomField2,
              custom3: getVisitData.data.Result.ContactInfo.CustomField3,
            })
          );
        } else {
          const ValidClient = await getValidClientForEvalContent(
            res.data.ListResult[0].Clientid,
            "2"
          );

          if (ValidClient?.data?.ErrorMessage) {
            dispatch(
              SHOW_NOTIFICATION({
                message: ValidClient?.data?.ErrorMessage,
                type: "error",
                head: "Error !",
              })
            );
          } else {
            handleNext();
          }
        }
        //handleNext();
      }
    }
  } catch (e) {
    console.log(e, "e");
  } finally {
    dispatch(HIDE_LOADER());
  }
};

const getLocationForTreatment = async ({
  zipCode,
  companyId,
  therapistGender,
  dispatch,
  handleNext,
}: CommonArgs) => {
  const pos = parseInt(companyId!) === 3 ? "2" : "1";
  const genderid = therapistGender === "Male" ? "1" : "2";
  try {
    dispatch(SHOW_LOADER());
    const res = await getLocationForTreatmentApi(
      zipCode!,
      companyId!,
      genderid!,
      pos
    );
    //console.log(res, "res");
    if (res.data.ListResult.length > 0) {
      const alternateLocations = res.data.ListResult.filter(
        (location: LocationArgs) => location.IsAlternateLocation === true
      );
      const recommendedLocations = res.data.ListResult.filter(
        (location: LocationArgs) => location.IsRelativeLocation === true
      );
      dispatch(User({ recommendedLocations, alternateLocations }));
      handleNext && handleNext();
    } else {
      dispatch(User({ recommendedLocations: [], alternateLocations: [] }));
      handleNext && handleNext();
    }
  } catch (e) {
    console.log(e, "e");
  } finally {
    dispatch(HIDE_LOADER());
  }
};

const getAvailableSlots = async (param: CommonArgs) => {
  const {
    clientID,
    companyId,
    locName,
    treatmetnLocationId,
    therapistGender,
    dispatch,
    handleNext,
  } = param;
  const pos = parseInt(companyId!) === 3 ? "2" : "1";
  const genderid = therapistGender === "Male" ? "1" : "2";
  try {
    dispatch(SHOW_LOADER());
    const res = await getAvailableSlotsApi(
      clientID!,
      treatmetnLocationId!,
      genderid!,
      pos!
    );
    if (res.data.ListResult.length > 0) {
      const slots = res.data.ListResult;
      dispatch(
        User({ slotList: { name: locName, list: slots, treatmetnLocationId } })
      );
      handleNext && handleNext();
    }
  } catch (e) {
    console.log(e, "e");
  } finally {
    dispatch(HIDE_LOADER());
  }
};

const createAppointment = async (data: any) => {
  try {
    const res = await createAppointmentApi(data);
    return res;
  } catch (e) {
    console.log(e, "e");
  }
};

const getFutureVisitContent = async (clientid: string, SMSDate: string) => {
  try {
    const res = await getFutureVisitApi(clientid, SMSDate);
    return res;
  } catch (e) {
    console.log(e, "e");
  }
};

const getValidClientForEvalContent = async (
  clientid: string,
  treatmentTypeId: string
) => {
  try {
    const res = await getValidClientForEval(clientid, treatmentTypeId);
    return res;
  } catch (e) {
    console.log(e, "e");
  }
};

const validateUserInfoForm = async (
  schema: any,
  formData: any,
  dispatch: any
) => {
  try {
    await schema.validate(formData, { abortEarly: false });
    dispatch(User({ errorMsg: {} }));
    return true;
  } catch (error: any) {
    if (error instanceof Yup.ValidationError) {
      const validationErrors: { [key: string]: any } = error.inner.reduce(
        (acc: { [key: string]: any }, err) => {
          if (err.path) {
            acc[err.path] = err.message;
          }
          return acc;
        },
        {}
      );
      dispatch(User({ errorMsg: { ...validationErrors } }));
      // setErrors(validationErrors);
      throw error;
    } else {
      console.error("Unexpected error:", error);
      return error;
    }
  }
};

export {
  getLinkData,
  getClientData,
  getClientsByPhoneDOB,
  getLocationForTreatment,
  getAvailableSlots,
  createAppointment,
  getFutureVisitContent,
  validateUserInfoForm,
  getValidClientForEvalContent,
};
