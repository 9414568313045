import React, { useEffect, useState } from "react";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  styled,
  Box,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLinkData } from "./utils";
import Step1Form from "./components/step1Form";
import Step2Form from "./components/step2Form";
import Step3Form from "./components/step3Form";
import { FormHeadingTypography } from "../../components/FormComponent/CustomElements";
import { SHOW_SUCCESS_MODEL } from "../../redux/slices/successModelSlice";
import dateFormat from "../../utils/dateFormat";
import SuccessModal from "../../components/FormComponent/SuccessModel";
import { saveConsentApi } from "./api";
import moment from "moment";
const MedicalHistory: React.FC = () => {
  const modelData = useSelector((state: any) => state.successModelReducer);
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const {
    brandLogo,
    decryptApiData,
    recommendedLocations,
    slotDetails,
    treatmentLocationDetail,
    selectedClient,
    companyID,
  } = useSelector((state: any) => state.UserReducer);
  const { dob, firstName, lastName, acknowledge, consentBy, brandName } =
    useSelector((state: any) => state.profileReducer);
  const showConsentStep =
    decryptApiData?.S === "8" && selectedClient?.ConsentBy == null; // 8  for client and 9 for schedular
  const steps = [
    ["", ""],
    ["", ""],
    ["", ""],
  ];

  const queryParams = new URLSearchParams(location.search);
  const clientLink = queryParams.get("OT");

  const handleNext = () => {
    //alert(JSON.stringify(selectedClient.ConsentBy))
    // return;
    // if (showConsentStep) {
    //   // cover all page if showConsentStep is true
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // } else {
      if (activeStep === 2) {
        handleSubmit();
      } else {
        //alert("dsd");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    //}
  };

  const saveConsentData = async () => {
    try {
      var getDate = moment().format("MM/DD/YYYY HH:mm:ss");
      const timeStampForFileName = moment().format("YYYYMMDD_HHmmss");
      // Construct the consent data object
      const clientConsentDTO = {
        ClientId: selectedClient?.Clientid,
        //ConsentFilePath: "path",
        ConsentFileName: `${
          selectedClient?.Clientid ?? "unknown"
        }_Consent_${timeStampForFileName}.pdf`,
        ConsentBy: consentBy,
        ConsentDate: getDate.toString(),
        BrandCode: selectedClient?.BrandCode,
        BrandName: selectedClient?.BrandName,
        LanguageID: "1", //data is not present in the response
        CompanyId: companyID,
        LastName: selectedClient?.LastName,
        FirstName: selectedClient?.FirstName,
        DOB: selectedClient?.DOB,
        ContactInfoDTO: selectedClient?.ContactInfoDTO,
        //NoSMS: !chkAllowSendSMS.checked,
      };
      //console.log(JSON.stringify(clientConsentDTO));
      //alert(JSON.stringify(clientConsentDTO));
      // Save consent info by calling the API
      const response = await saveConsentApi(clientConsentDTO);
      //console.log(JSON.stringify(response));
      //alert(JSON.stringify(clientConsentDTO));
      return response;
    } catch (error) {
      console.error("Error saving consent data:", error);
      throw error; // Optionally re-throw the error for handling elsewhere
    }
  };

  const handleSubmit = () => {
    //console.log(JSON.stringify(recommendedLocations));
    //return;
    //dispatch(SHOW_SUCCESS_MODEL({ visiblity: true }));
    //alert(treatmentLocationDetail.ContactInfo.CustomField1);
    if (showConsentStep) {
      saveConsentData();
    }
    dispatch(
      SHOW_SUCCESS_MODEL({
        visiblity: true,
        isNew: true,
        date: dateFormat(slotDetails.Start.toString()).toUpperCase(),
        //FirstAvailableSlot:recommendedLocations.FirstAvailableSlot,
        location: treatmentLocationDetail.LocationName,
        address:
          treatmentLocationDetail.AddressLine1 +
          treatmentLocationDetail.AddressLine2,
        address1: treatmentLocationDetail.AddressLine1,
        address2: treatmentLocationDetail.AddressLine2,
        custom1: treatmentLocationDetail.ContactInfo.CustomField1,
        custom2: treatmentLocationDetail.ContactInfo.CustomField2,
        custom3: treatmentLocationDetail.ContactInfo.CustomField3,
        // futureVisit?.data?.Result?.TreatmentLocationAddressLine1 +
        // futureVisit?.data?.Result?.TreatmentLocationAddressLine2,
      })
    );
  };
  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateLink = async () => {
    await getLinkData({ clientLink: clientLink ?? "", dispatch });
  };

  useEffect(() => {
    if (activeStep === 0 && !clientLink) {
      // if OT in url, this will not call and use data from evalschedule redux
      validateLink();
    }
  }, [activeStep]);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue =
        "Are you sure you want to leave? Any unsaved changes will be lost.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getStepForms = (step: number) => {
    switch (step) {
      case 0:
        return <Step1Form handleNext={handleNext} />;
      case 1:
        return <Step2Form handlePrev={handlePrev} handleNext={handleNext} />;
      case 2:
        return (
          <Step3Form
            handlePrev={handlePrev}
            handleNext={handleNext}
            showConsentStep={showConsentStep}
          />
        );
      default:
        return "Unknown step";
    }
  };
  const handleCloseModel = () => {
    dispatch(SHOW_SUCCESS_MODEL({ visiblity: false }));
  };
  return (
    <Container maxWidth="xl" sx={{ flex: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Box sx={{}}>
          <img
            src={require(`../../assets/images/${brandLogo}`)}
            alt="Brand"
            className="brand-image"
          />
        </Box>
        <FormHeadingTypography sx={{ mb: 2 }}>
          Lets Collect Some Of Your Medical History
        </FormHeadingTypography>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
          sx={{ width: "-webkit-fill-available", mb: 2 }}
        >
          {steps.map((label, index) => {
            return (
              <Step key={index}>
                <CustomStepLabel completed={index < activeStep}>
                  {label.map((text, index) => (
                    <div key={index}>{text}</div>
                  ))}
                </CustomStepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          {getStepForms(activeStep)}
        </Box>
      </Box>
      {modelData.visiblity && (
        <SuccessModal
          open={modelData.visiblity}
          onClose={() => handleCloseModel()}
          {...modelData}
        />
      )}
    </Container>
  );
};

export default MedicalHistory;

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  top: 30,
  left: "calc(-50% + 65px)",
  right: "calc(50% + 65px)",
}));

const CustomStepLabel = styled(StepLabel)<{ completed?: boolean }>(
  ({ theme, completed }) => ({
    "& .MuiStepLabel-label": {
      // color: completed ? theme.palette.success.main : 'inherit', // Text color
      marginTop: "0px",
      "&.MuiStepLabel-alternativeLabel": {
        marginTop: "5px",
        fontSize: "14px",
      },
    },
    "& .MuiSvgIcon-root": {
      color: completed ? theme.palette.success.main : "inherit", // Icon color
    },
  })
);
