import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_LOADER, HIDE_LOADER } from "../../../../redux/slices/loaderSlice";
import { MedicalHistory } from "../../../dashboard/slice";
import { DiagnosisIssueOptions } from "../step1Form/options";
import { saveMedicalHistory, validateUserInfoForm } from "../../utils";
import { DiagnosisIssueSchema } from "../../schema";
import { Errors } from "../../types";

interface UserInfoFormProps {
  handlePrev: () => void;
  handleNext: () => void;
}

const Step2Form: React.FC<UserInfoFormProps> = ({ handlePrev, handleNext }) => {
  const dispatch = useDispatch();
  const { decryptApiData, medicalHistory,treatmentLocationDetail } = useSelector(
    (state: any) => state.UserReducer
  );
  const { DiagnosisIssue: diagnosisIssue } = medicalHistory || {};

  const { TherapistType } = medicalHistory || {};
  const { VisitId } = treatmentLocationDetail || {};
  const [errors, setErrors] = useState<Errors>({});

  // Initialize formData with diagnosisIssue
  const [formData, setFormData] = useState({
    diagnosisIssue: diagnosisIssue || "",
  });

  // Convert comma-separated string to an array of selected diagnosis issues
  const [selectedDiagnosisIssues, setSelectedDiagnosisIssues] = useState<
    string[]
  >(diagnosisIssue ? diagnosisIssue.split(";") : []);

  useEffect(() => {
    setSelectedDiagnosisIssues(diagnosisIssue ? diagnosisIssue.split(";") : []);
  }, [diagnosisIssue]);
  const handleChange = (event: {
    target: { name: string; checked: boolean };
  }) => {
    const { name, checked } = event.target;
  
    if (name === "None" && checked) {
      // If "None" is selected, clear all other options
      setSelectedDiagnosisIssues(["None"]);
      setFormData((prev) => ({
        ...prev,
        diagnosisIssue: "None", // Set only "None" in the form data
      }));
    } else {
      setSelectedDiagnosisIssues((prevItems) => {
        // If "None" is already selected and another option is checked, clear "None"
        if (prevItems.includes("None")) {
          return checked ? [name] : prevItems.filter((item) => item !== "None");
        }
  
        // Add or remove the selected option from the array
        return checked
          ? [...prevItems, name] // Add item to the array
          : prevItems.filter((item) => item !== name); // Remove item from the array
      });
  
      // Update formData with the updated selected items as a comma-separated string
      setFormData((prev) => ({
        ...prev,
        diagnosisIssue: checked
          ? [...selectedDiagnosisIssues.filter((item) => item !== "None"), name].join(";")
          : selectedDiagnosisIssues.filter((item) => item !== name).join(";"),
      }));
    }
  };
  

  // const handleChange = (event: {
  //   target: { name: string; checked: boolean };
  // }) => {
  //   const { name, checked } = event.target;

  //   setSelectedDiagnosisIssues((prevItems) => {
  //     if (checked) {
  //       return [...prevItems, name]; // Add item to the array
  //     } else {
  //       return prevItems.filter((item) => item !== name); // Remove item from the array
  //     }
  //   });

  //   // Update formData with the updated selected items as a comma-separated string
  //   setFormData((prev) => ({
  //     ...prev,
  //     diagnosisIssue: checked
  //       ? [...selectedDiagnosisIssues, name].join(";")
  //       : selectedDiagnosisIssues.filter((item) => item !== name).join(";"),
  //   }));
  // };

  const handleSubmit = async () => {
    try {

      dispatch(SHOW_LOADER());
      await validateUserInfoForm(DiagnosisIssueSchema, formData, setErrors);
      const payload = {
        ...formData,
        VisitId: VisitId,
        OnbordingEventType: 1,
        clientid: parseInt(decryptApiData.S === "8" ? decryptApiData?.ID : ""),
        updateBy: parseInt(decryptApiData?.ID),
      };
      //alert(JSON.stringify(TherapistType));
      //alert(JSON.stringify(payload));
      await saveMedicalHistory(payload);
      // Dispatch the updated diagnosisIssue to Redux
      dispatch(
        MedicalHistory({
          DiagnosisIssue: formData.diagnosisIssue,
        })
      );

      handleNext();
    } catch (e) {
      console.error(e, "--- Error in form submission");
    } finally {
      dispatch(HIDE_LOADER());
    }
  };

  const options = DiagnosisIssueOptions;

  return (
    <Grid
      container
      spacing={1}
      style={{ marginLeft: "170px", padding: "24px" }}
      md={10}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          align="left"
          gutterBottom
          sx={{ fontWeight: "bold", fontSize: "17px" }}
        >
          Have you, or the person you are responsible for ever had any of the
          following?
        </Typography>
      </Grid>
      {options.map((item, index) => (
        <Grid item xs={12} sm={4} key={item.id}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDiagnosisIssues.includes(item.id)}
                    onChange={handleChange}
                    name={item.id}
                    color="primary"
                  />
                }
                label={item.label}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      ))}

      {errors.diagnosisIssue && (
        <Grid item xs={12} textAlign={"center"}>
          <Typography color="error" variant="h6">
            Please select at least one option.
          </Typography>
        </Grid>
      )}

      <Grid
        item
        md={12}
        sx={{ display: "flex", justifyContent: "center", gap: 3 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handlePrev}
          sx={{ width: "100px" }}
        >
          PREVIOUS
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: "100px" }}
        >
          NEXT
        </Button>
      </Grid>
    </Grid>
  );
};

export default Step2Form;
