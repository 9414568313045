import React from 'react';
import "./style.css";
const InvalidPage: React.FC = () => {
    return (
        <div className="section">
            <h1 className="error">404</h1>
            <div className="page">Ooops!!! The page you are looking for is not found</div>
        </div>
    );
}
export default InvalidPage;