const API = {
    LOGIN: "/api/Client/GetClientsByPhone/?searchString={0}",
    DecryptParams: "/api/Login/DecryptionParameter", //data = 
    GetClientById: "/api/Client/GetClientById", // ?clientID=355841
    GetClientByPhoneDob: "/api/Client/GetClientsByPhoneDOB", // ?companuId,DOB,Mobilenumber
    ValidClientForEval: "/api/Visit/ValidClientForEval?&Treatmenttypeid=2&",//hard code as per manoj discuss Treatmenttypeid=2
    getLocationForTreatment: "/api/Availability/GetEvalAvailTreatmetnLocationByZipGender", // ?zipcode,companyid=3&genderid=1&pos=2
    fetchAvailableSlots: "/api/Availability/GetPTEvalAvailableSlots", // ??clientid&treatmetnLocationid&genderid=1&POS=2
    createEvalAppointmentBySchool: "api/visit/CreateEvalAppointmentBySchoolNew",
    getFutureVisit: "/api/Visit/GetSMSFutureVisitData", // ?clientid=355841&SMSDate=07/29/2024 // dd/mm/yyyy
    getTreatmentLocationById: "/api/Location/GetTreatmentLocationByID",///?treatmentlocationId=26
    getStates: "/api/ListData/GetListData?listTypeId=1", // ?listTypeId=1
    updateBio: "/api/Client/UpdateClientBioDetails", // ?clientid=355841
    loadConsent: "/api/Consent/GetConsentByCompany?companyId=3&languageId=1",//companyId=1&languageId=1"
    saveConsentForm:"/api/Client/SaveClientConsentWithFile",//SaveClientConsent",
    updateMedicalHistory: "api/MedicalHistory/SaveMedicalHistoryByOnbording",
}

export { API }

