import { StyledDialog, FormHeadingTypography, FormSubHeadingTypography } from '../../../../components/FormComponent/CustomElements';
import { DialogTitle, DialogContent, Dialog } from '@mui/material';
interface ModalProps {
    open: boolean;
    onClose: () => void;
    content: any;
}

const ConsentPopup: React.FC<ModalProps> = ({ open, onClose, content }) => {
    return (
        <Dialog open={open} onClose={onClose} sx={{ width: "auto !important" }}>
            <DialogTitle>
                <FormHeadingTypography sx={{ textAlign: 'center' }}>{content.Header}</FormHeadingTypography>
            </DialogTitle>
            <DialogContent sx={{
                maxHeight: '400px', // Set a maximum height for the content
                overflowY: 'auto',  // Enable scrolling when content exceeds the max height
            }}>
                <FormSubHeadingTypography dangerouslySetInnerHTML={{ __html: content.Body }} />
               
            </DialogContent>
        </Dialog>
    )
}
export default ConsentPopup;