import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../screens/dashboard'
import ProfileUpdate from '../screens/profileUpdate'

export const PageRoute = {
    NotFound: '*',
    Home: '/Evalschedule',
}
const redirectURL = "/Evalschedule"
const AppRoutes = () => {
    return (
        <Routes>
            <Route path={"/Evalschedule"} element={<Dashboard />} />
            <Route path={"/update-profile"} element={<ProfileUpdate />} />
            <Route path="*" element={<Navigate to={redirectURL} replace />} />
        </Routes>
    )
}

export default AppRoutes