import React, { ChangeEvent } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import ErrorMsg from '../../../../components/FormComponent/ErrorMsg';
import FormLayout from "../FormLayout";

interface FormData {
  brandLogo: string;
  mobile: string;
  dateChange: (date: moment.Moment | null) => void;
  moNoChange: (e: ChangeEvent<HTMLInputElement>) => void;
  validate: () => void;
  errorMsg: {
    [key: string]: string | undefined;
  };
  listOfClient: any[];
  selectedClient: any;
  handleClientSelect: (
    event: React.ChangeEvent<{ value: unknown }> | SelectChangeEvent<any>,
    child: React.ReactNode
  ) => void;
}
const subHeading =
  "There are multiple clients associated with phone number. Please select the client that you want to log in for.";

  //const updateVal = (e: React.ChangeEvent<HTMLInputElement>) => setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));

const Form: React.FC<FormData> = ({
  brandLogo,
  mobile,
  dateChange,
  moNoChange,
  validate,
  errorMsg,
  listOfClient,
  selectedClient,
  handleClientSelect,
}) => {
  return (
    <FormLayout
      brandLogo={brandLogo}
      heading={"Please Verify Identity"}
      validate={validate}
      listOfClient={listOfClient}
      // subHeading={listOfClient.length > 1 ? subHeading : undefined}
    >
      <TextField
        label="Your Mobile Number"
        value={mobile || ""}
        variant="outlined"
        size="medium"
        margin="normal"
        fullWidth
        //disabled
        required
        className="custom-input"
        onChange={moNoChange}
        InputLabelProps={{
          sx: {
            "&.Mui-required::after": {
              color: "red",
            },
          },
        }}
      />
      <Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Date of birth"
            name="dob"
            disableFuture
            slotProps={{
              textField: {
                placeholder: "Select a date",
                className: "custom-input",
                // disabled: true,
                error: !!errorMsg.dob,
                sx: {
                  "& .MuiInputBase-root": {
                    "&.Mui-error": {
                      borderColor: "red",
                    },
                  },
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "red",
                    },
                },
              },
            }}
            onChange={dateChange}
          />
        </LocalizationProvider>
      </Box>
      {listOfClient.length > 1 && (
        <FormControl fullWidth required error={!!errorMsg.selectedClient}>
          <InputLabel id="client-select-label">Client</InputLabel>
          <Select
            labelId="client-select-label"
            name="selectedClient"
            id="client-select"
            value={selectedClient?.Clientid || ""}
            label="Client"
            required
            fullWidth
            className="custom-input"
            onChange={handleClientSelect}
          >
            {listOfClient.map((client: any) => (
              <MenuItem key={client.Clientid} value={client.Clientid}>
                {client.FirstName}-{client.serviceLine}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </FormLayout>
  );
};

export default Form;
