import { HIDE_LOADER, SHOW_LOADER } from '../../../redux/slices/loaderSlice'
import { Profile } from '../../profileUpdate/slice'
import { CommonArgs } from '../types'
import { validateLinkApi, getClientDataByIdApi } from '../../dashboard/api'
import { updateBioApi, loadConsentApi } from '../../profileUpdate/api'
import * as Yup from 'yup'
import { Gender } from "../../../utils/constant"

const getLinkData = async ({ clientLink, dispatch, setError }: CommonArgs) => {
    dispatch(SHOW_LOADER())
    try {
        const res = await validateLinkApi(clientLink as string)
        const params = new URLSearchParams(res.data.Result);
        const obj = Object.fromEntries(params.entries());

        dispatch(Profile({ decryptApiData: obj }))
        await getClientData({ clientID: obj.ID, dispatch })
    }
    catch (e) {
        setError && setError('Invalid Link')
        dispatch(HIDE_LOADER())
    }
}

const getClientData = async ({ clientID, dispatch }: CommonArgs) => {
    try {
        dispatch(SHOW_LOADER())
        const res = await getClientDataByIdApi(clientID as string)
        const payload = {
            firstName: res.data.Result.FirstName,
            lastName: res.data.Result.LastName,
            dob: res.data.Result.DOB.split('T')[0],
            gender: Gender.find(gender => gender.label === res.data.Result.Gender)?.value,
            otherGender: res.data.Result.OtherGender || '',

            mobile: res.data.Result.ContactInfoDTO.Mobile,
            email: res.data.Result.ContactInfoDTO.Email,
            textNotification: res.data.Result.NoSMS,
            emailNotification: res.data.Result.NoEmail,

            addressLine1: res.data.Result.ContactInfoDTO.Address1,
            addressLine2: res.data.Result.ContactInfoDTO.Address2,
            city: res.data.Result.ContactInfoDTO.City,
            state: res.data.Result.ContactInfoDTO.State,
            zipCode: res.data.Result.ContactInfoDTO.Zip,

            acknowledge: false,
            consentBy: res.data.Result.ConsentBy,

            brandLogo: res.data.Result.BrandLogo,
            brandName: res.data.Result.BrandName,
            clientID: res.data.Result.Clientid,
            companyID: res.data.Result.CompanyId,
        }
        dispatch(Profile(payload))
    }
    catch (e) {
        console.log(e, "e")
    }
    finally {
        dispatch(HIDE_LOADER())
    }
}

const validateUserInfoForm = async (schema: any, formData: any, setErrors: (arg0: {}) => void) => {
    try {
        await schema.validate(formData, { abortEarly: false });
        setErrors({});
        return true;
    }
    catch (error: any) {
        if (error instanceof Yup.ValidationError) {
            const validationErrors: { [key: string]: any } = error.inner.reduce((acc: { [key: string]: any }, err) => {
                if (err.path) {
                    acc[err.path] = err.message;
                }
                return acc;
            }, {});
            console.log(validationErrors, "validationErrors")
            setErrors(validationErrors);
            throw error;
        } else {
            console.error('Unexpected error:', error);
            return error;
        }
    }
};

const saveUserInfo = async (formData: any) => {
    try {
        const payload = {
            clientBioUpdateAction: formData.clientBioUpdateAction,
            clientID: formData.clientid,
            updateBy: formData.updateBy,
            lastName: formData.lastName,
            firstName: formData.firstName,
            dob: formData.dob,
            sex: formData.gender,
            otherGender: formData.otherGender,
        }
        await updateBioApi(payload)
    }
    catch (e) {
        // throw e;
        console.log(e, "e")
    }
}

const saveUserAddress = async (formData: any) => {
    try {
        const payload = {
            clientBioUpdateAction: formData.clientBioUpdateAction,
            clientID: formData.clientid,
            updateBy: formData.updateBy,
            address1: formData.addressLine1,
            address2: formData.addressLine2,
            city: formData.city,
            state: formData.state,
            zip: formData.zipCode,
        }
        await updateBioApi(payload)
    }
    catch (e) {
        // throw e;
        console.log(e, "e")
    }
}

const saveUserCommunication = async (formData: any) => {
    try {
        const payload = {
            clientBioUpdateAction: formData.clientBioUpdateAction,
            clientID: formData.clientid,
            email: formData.primaryEmail,
            mobile: formData.mobile,
            noSMS: formData.textNotification,
            noEmail: formData.emailNotification,
        }
        await updateBioApi(payload)
    }
    catch (e) {
        // throw e;
        console.log(e, "e")
    }
}
const loadConsent = async (callback: (data: any) => void, brandName: string, brandCode: string): Promise<void> => {
    try {
        const res = await loadConsentApi();
        const finalHeader = res.data.Result.Header.replace("{brandName}", brandName)
        console.log(res.data.Result.Body, "--res.data.Result.Body");
        let updatedHtmlString = res.data.Result.Body.replace(/{brandName}/g, brandName);
        updatedHtmlString = updatedHtmlString.replace(/{brandCode}/g, brandCode);
        callback({ ...res.data.Result, Body: updatedHtmlString, Header: finalHeader });
    }
    catch (e) {
        console.log(e, "--loadConsent");
    }
};
export {
    getLinkData,
    getClientData,
    validateUserInfoForm,
    saveUserInfo,
    saveUserAddress,
    saveUserCommunication,
    loadConsent
}