
import styled from '@emotion/styled';
import { Typography, Dialog, Box, Button } from '@mui/material';

const FormHeadingTypography = styled(Typography)`
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 960px) {
  }

  @media (min-width: 961px) {
  }
`;

const FormHeadingTypography16 = styled(Typography)`
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 960px) {
  }

  @media (min-width: 961px) {
  }
`;

const FormSubHeadingTypography = styled(Typography)`
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 960px) {
  }

  @media (min-width: 961px) {
  }
`;

const FormFooterTypography = styled(Typography)`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;

  @media (max-width: 600px) {
  }

  @media (min-width: 601px) and (max-width: 960px) {
  }

  @media (min-width: 961px) {
  }
`;

const StyledDialog = styled(Dialog)(({ theme }) => ({
  backdropFilter: 'blur(3.75px)',
  gap: '16px',
  '& .MuiPaper-root': {
    backdropFilter: 'blur(3.75px)',
    maxHeight: 'max-content',
    maxWidth: '350px',
  }
}));

const CircularBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 80,
  height: 80,
  borderRadius: '50%',
  backgroundColor: '#00A1C1',
  gap: '16px',
}));

const HeadBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '3px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  backgroundColor: '#003366'
}));

export {
  FormHeadingTypography,
  FormSubHeadingTypography,
  FormHeadingTypography16,
  FormFooterTypography,
  StyledDialog,
  CircularBox,
  HeadBar
}