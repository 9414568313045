import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AppRoutes from './routes'
import { Toaster, Loader } from './components'
import { Header, Footer } from './components'
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

const App: React.FunctionComponent = () => {
  const notification = useSelector((state: { showNotification: any }) => state.showNotification)
  const isLoader = useSelector((state: { loader: any }) => state.loader)

  const [viewBoxWidth, setViewBoxWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewBoxWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {notification.show && <Toaster />}
      {isLoader.show && <Loader />}
      <Header width={viewBoxWidth} />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <Footer width={viewBoxWidth} />
    </ThemeProvider>
  )
}

export default App;
